export default function SelectWrapper({ id= undefined, defaultValue="", list = [], onChange = () => { }, placeholder = undefined, className = "", disabled }) {
    return (
        <select
            id={id}
            onChange={onChange}
            defaultValue={defaultValue}
            disabled={disabled}
            className={className}
        >
            {placeholder !== undefined && list.length !== 1 && <option disabled value="">{placeholder}</option>}
            {list.map(item => (
                <option key={item._id} value={item._id}>{item.name}</option>
            ))}
        </select>
    );
}
