import "./NoticeDetailView.css";
import { Row, Col } from "react-bootstrap";
import common from "../../../common/common";
import { useTranslation } from "react-i18next";
import { useState, useEffect, useCallback, useContext } from "react";
import { POPUP as GeneralPopup } from "../../../common/defines";
import dgLogger from "../../../common/dgLogger";
import Api from "../../../Api";
import GeneralErrorWindow from "../../common/GeneralErrorWindow";
import { useNavigate, useParams } from "react-router-dom";
import LoadingSpinner from "../../common/LoadingSpinner";
import Emblem from "../../common/Emblem";
import { AppContext } from "../../../AppContext";
import { utility } from "@ocean-knight/shared";
import Modal from "../../common/Modal";
import { useMobile, usePC } from "../../common/MediaQuery";
import Editor from "../../TinyEditor";
import { useIsMount } from "../../../common/customHook";
import _ from "lodash";

const POPUP = {
    ...GeneralPopup,
    RemoveNoticeConfirm: 1003,
};

export const PopupRemoveNoticeConfirm = ({ onConfirm, onCancel }) => {
    const { t } = useTranslation();
    const header = <div>{t("914")}</div>;
    const body = <div>{t("915")}</div>;

    return <Modal onRequestClose={onCancel} onConfirm={onConfirm} onCancel={onCancel} header={header} body={body} />;
};

export default function NoticeDetailView(props) {
    const { t } = useTranslation();
    const { _id } = useParams();
    const navigate = useNavigate();
    const context = useContext(AppContext);
    const isPc = usePC();
    const isMobile = useMobile();
    const isMount = useIsMount();
    const [user, setUser] = useState({});
    const [notice, setNotice] = useState({});
    const [state, setState] = useState({
        popup: POPUP.None,
        popupTarget: null,
        isLoading: true,
    });

    useEffect(() => {
        const getUserInfo = async () => {
            if (context.loggedIn) {
                const user = await Api.getCurrentUserInfo({ optPermissions: true });
                setUser(user);
            }
        };
        getUserInfo();
    }, [context.loggedIn]);

    useEffect(() => {
        const getNotice = async () => {
            try {
                if (!isMount.current) return;
                const payload = await Api.getNotice(_id);
                payload.createdAt = new Date(payload.createdAt).toLocaleString("ko-KR", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: false,
                });
                payload.isAllGroups = _.isEqualWith(
                    _.sortBy(payload.allGroups || [], ["groupId"]),
                    _.sortBy(payload.groups || [], ["groupId"])
                );

                payload.content = common.base64ToUtf8(payload.content);
                setNotice(payload);
                setState((prev) => ({ ...prev, isLoading: false }));
            } catch (e) {
                dgLogger.error(e)();
                setState((prev) => ({ ...prev, popup: POPUP.GeneralError, popupTarget: e.toString() }));
            }
        };

        getNotice();
    }, [_id, isMount]);

    const removeNotice = useCallback(
        async (_id) => {
            await Api.removeNotice(_id);
            common.navigateBack(navigate);
        },
        [navigate]
    );

    const popups = useCallback(() => {
        switch (state.popup) {
            case POPUP.GeneralError:
                return <GeneralErrorWindow message={state.popupTarget} onClick={() => navigate("/notice")} />;
            case POPUP.RemoveNoticeConfirm:
                return <PopupRemoveNoticeConfirm onConfirm={() => removeNotice(_id)} onCancel={() => setState((prev) => ({ ...prev, popup: POPUP.None }))} />;
            case POPUP.None:
                break;
            default:
                console.log(`Can not handle ${state.popup} popup type`);
        }
    }, [navigate, state.popup, state.popupTarget, removeNotice, _id]);

    const isModifiable = useCallback(() => {
        // 공지를 등록한 사용자 이거나, 관리자일 경우
        if (!user.optPermissions) return false;
        if (!user._id) return false;

        const hasSiteAdminPermission = user.optPermissions.find(x => utility.hasSiteAdminPermission(x.grade));
        const hasAllRequiredPermissions = notice.groups?.every((group) => {
            const perm = user.optPermissions.find((permission) => permission.group === group.groupId);
            return utility.hasGroupManagerPermission(perm?.grade);
        });

        return hasAllRequiredPermissions || hasSiteAdminPermission;
    }, [user.optPermissions, user._id, notice.groups]);

    return (
        <div id="notice-detail-view">
            <LoadingSpinner isOpen={state.isLoading} />
            {popups()}
            <Row className="align-items-center pt-30px:sm">
                <Col className="col">
                    <label
                        className="c-000
                    notosanskr-40b notosanskr-26b:md notosanskr-20b:sm
                    letter-spacing--1px letter-spacing--0.65px:md letter-spacing--0.5px:sm"
                    >
                        {t("916")}
                    </label>
                </Col>
                {isModifiable() && (
                    <Col
                        className="col-auto d-flex text-end
                    notosanskr-16 notosanskr-13:sm
                    letter-spacing--0.8px letter-spacing--0.65px:sm
                    post"
                    >
                        <div className="me-2">
                            <input
                                type="submit"
                                className="c-fff edit"
                                onClick={() => {
                                    navigate(`/notice/edit/${_id}`);
                                }}
                                value={t("537")}
                            />
                        </div>
                        <div>
                            <input
                                type="submit"
                                className="c-fff remove"
                                onClick={() => {
                                    setState((prev) => ({ ...prev, popup: POPUP.RemoveNoticeConfirm }));
                                }}
                                id="submit"
                                value={t("465")}
                            />
                        </div>
                    </Col>
                )}
            </Row>
            <Row
                className="align-items-center
            mt-83px mt-35px:md mt-22px:sm"
            >
                <Col className="col">
                    <span
                        className="nanumsquare-16 nanumsquare-14:md nanumsquare-10:sm
                    ls--0.4px ls--0.35px:md letter-spacing--0.5px:sm
                    c-999999"
                    >
                        {notice.isAllGroups ?
                            t("404")
                            :
                            notice.groups?.map((group) => group.groupName).join(", ")
                        }
                    </span>
                </Col>
            </Row>
            <Row className="align-items-center mt-24px mt-12px:md mt-8px:sm">
                <Col>
                    <span
                        className="nanumsquare-28b nanumsquare-22b:md nanumsquare-16b:sm
                    ls--0.7px ls--0.55px:md ls--08.px:sm
                    c-333333"
                    >
                        {notice.title}
                    </span>
                </Col>
            </Row>
            <Row
                className="align-items-center
            mt-48px mt-20px:md mt-16px:sm"
            >
                <Col className="col-auto">
                    <div className="">
                        <Emblem
                            width={isMobile ? "30px" : "40px"}
                            height={isMobile ? "30px" : "40px"}
                            url={notice.authorPictureUrl}
                            char={notice.authorName}
                            charClass={`avatar ${isMobile ? "avatar-30" : "avatar-40"} bg-e8ebf0`}
                        />
                    </div>
                </Col>
                <Col className="d-flex">
                    <div>
                        <span
                            className="c-333333
                            notosanskr-16 nanumsquare-14:md nanumsquare-10:sm
                        letter-spacing--0.35px:md letter-spacing--0.5px:sm"
                        >
                            {notice.authorName}
                        </span>
                    </div>
                    <div
                        className="c-999999
                    nanumsquare-16 nanumsquare-14:md nanumsquare-10:sm
                    letter-spacing--0.35px:md letter-spacing--0.5px:sm
                    pl-24px pl-20px:sm"
                    >
                        <span>{notice.createdAt}</span>
                    </div>
                </Col>
            </Row>

            <Row
                className="align-items-center gx-0
            mt-24px mt-16px:sm"
            >
                <Col
                    className="col-12 notice-content
                pt-32px pt-24px:md pt-16px:sm
                border-top
                overflow-auto"
                >
                    <Editor viewer={true} HTMLData={notice.content || ""} />
                </Col>
            </Row>
        </div>
    );
}
