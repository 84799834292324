import { useCallback, useEffect, useRef, useState } from "react";
import common from "../../../common/common";
import { POPUP as GeneralPopup } from "../../../common/defines";
import GeneralErrorWindow from "../../common/GeneralErrorWindow";
import { useNavigate, useNavigationType } from "react-router-dom";
import { useIsMount, useSafeAsyncEffect } from "../../../common/customHook";
import { Col, Row } from "react-bootstrap";
import Api from "../../../Api";
import { utility } from "@ocean-knight/shared";
import dgLogger from "../../../common/dgLogger";
import { InputText } from "../../Input";
import SelectWrapper from "../../CreateReport/common/SelectWrapper";

const POPUP = {
    ...GeneralPopup,
    UpdateReportLicenseStateFailure: 1004,
    FailedToGetUserInfo: 1005,
    FailedToUpdateUserInfo: 1006,
};

export default function Events() {
    const isMount = useIsMount();
    const [state, setState] = useState({
        popup: POPUP.None,
        popupTarget: null,
        groupID: null,
    });
    const [userInfo, setUserInfo] = useState(null);
    const [notMemberedGroups, setNotMemberedGroups] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const getNotMemberedGroupList = async () => {
            try {
                const payload = await Api.getNotMemberedGroupList();
                if (!isMount.current) return console.log("already unmounted");
                const groups = payload.groups.filter(v => v.require_join_confirm);
                setNotMemberedGroups(groups);
                if (groups.length > 0) {
                    setState(prev => ({...prev, groupID: groups[0]._id}));
                }
            } catch (e) {
                dgLogger.error(e)();
                setState((prev) => ({ ...prev, popup: POPUP.GeneralError, popupTarget: e.message }));
            }
        };
        isMount.current && getNotMemberedGroupList();
    }, [isMount]);

    const popups = () => {
        switch (state.popup) {
            case POPUP.GeneralError:
                return (
                    <GeneralErrorWindow
                        message={state.popupTarget}
                        onClick={() => {
                            navigate("/");
                        }}
                    />
                );
            case POPUP.FailedToGetUserInfo:
                return (
                    <GeneralErrorWindow
                        message={state.popupTarget}
                        onClick={() => setState({...state, popup: POPUP.None, popupTarget: null})}
                    />
                );
            case POPUP.FailedToUpdateUserInfo:
                return (
                    <GeneralErrorWindow
                        message={state.popupTarget}
                        onClick={() => setState({...state, popup: POPUP.None, popupTarget: null})}
                    />
                );
            case POPUP.UpdateReportLicenseStateFailure:
            case POPUP.None:
                break;
            default:
                console.log(`un-handled popup (${state.popup})`);
                break;
        }
    };

    const generateRandomString = useCallback((length = 16) => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        const charactersLength = characters.length;

        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * charactersLength);
            result += characters.charAt(randomIndex);
        }

        return result;
    }, []);

    return <>
    {popups()}
        <Row className="py-2 item-row gx-0 px-2 align-items-center notosanskr-500 font-size-18">
            <Col className="item col-10">설명</Col>
            <Col className="item col-2 text-center">실행</Col>
        </Row>
        <Row className="py-2 item-row gx-0 px-2 align-items-center notosanskr-500 font-size-16">
            <Col className="item col-10">
                <Row>
                    <Col>
                        사용자 기본권한 변경 (<span style={{ color: "red" }}>주의!!</span> 실제 사용자의 기본권한이 변경됨)
                    </Col>
                </Row>
                <Row>
                    <Col className="ps-4">대상</Col>
                    <Col className="ps-4 col-auto">email</Col>
                    <Col className="col-3">
                        <InputText
                            id="user-email-2"
                            defaultValue={""}
                            className="w-100"
                        />
                    </Col>
                    <Col className="ps-4 col-auto">권한</Col>
                    <Col className="col-3">
                        <SelectWrapper
                            id="user-builtin-permission"
                            className="w-100"
                            defaultValue={utility.permissionGradeToString(utility.MemberPermission)}
                            list={
                                [
                                    {_id:utility.permissionGradeToString(utility.SiteAdminPermission), name: "사이트 관리자 (!!주의!!)"},
                                    {_id:utility.permissionGradeToString(utility.GroupSupervisorPermission), name: "전체 그룹 관리자 (Supervisor)"},
                                    {_id:utility.permissionGradeToString(utility.MemberPermission), name: "사이트 회원"},
                                ]
                            }
                            placeholder={""}
                        />
                    </Col>
                </Row>
            </Col>
            <Col className="item col-2 text-center">
                <button
                    className="execute notosanskr-400 font-size-16"
                    type="button"
                    onClick={async (_) => {
                        const email = document.getElementById("user-email-2");
                        if (!email.value.trim()) return console.log("has no email");
                        const perm = document.getElementById("user-builtin-permission");
                        if (!perm?.value) return console.log("has no permission value");

                        try {
                            const userInfo = await Api.getUserInfo({
                                email: email.value.trim(),
                            });
                            console.assert(userInfo?.groups?.length > 0);
                            const userTypes = await Api.getUserType({
                                bitsAllSet: perm.value
                            });
                            console.assert(userTypes.length == 1);
                            await Api.updateUserInfoV2({
                                _id: userInfo._id,
                                permission: userInfo.groups.map(x => x.group ? x : ({...x, permission: userTypes[0]._id}))
                            });
                        } catch (e) {
                            dgLogger.error(e)();
                            return setState({ ...state, popup: POPUP.FailedToGetUserInfo, popupTarget: e.message });
                        }
                    }}
                >
                    보내기
                </button>
            </Col>
        </Row>
        <Row className="py-2 item-row gx-0 px-2 align-items-center notosanskr-500 font-size-16">
            <Col className="item col-10">
                <Row>
                    <Col>
                        사용자 정보 출력 (권한 등)
                    </Col>
                </Row>
                <Row>
                    <Col className="ps-4">대상</Col>
                    <Col className="col-auto">email</Col>
                    <Col className="col-3">
                        <InputText
                            id="user-email"
                            defaultValue={""}
                            className="w-100"
                        />
                    </Col>
                </Row>
                {userInfo && <Row>
                    <Col className="ps-4 text-break">
                        <div>user info</div>
                        <div className="maxh-400px overflow-auto">
                            <div className="pb-2">_id : {userInfo?._id}</div>
                            <div className="pb-2">state: {userInfo?.state}</div>
                            <div className="pb-2">name: {userInfo?.name}</div>
                            <div className="pb-2">realname: {userInfo?.realname}</div>
                            {userInfo?.push_tokens?.android?.length > 0 && <div className="pb-2">push_tokens(android): {userInfo?.push_tokens?.android?.join(" / ")}</div>}
                            {userInfo?.push_tokens?.ios.length > 0 && <div className="pb-2">push_tokens(ios): {userInfo?.push_tokens?.ios?.join(" / ")}</div>}
                            <div>permissions: {userInfo?.permissions?.map((perm, i) =>
                                <div key={i} className="ps-4 pb-2 border-bottom">
                                    group: {perm.group}<br/>
                                    grade name : {perm.grade_name}<br/>
                                    grade(in deciman): {perm.grade}<br/>
                                    grade(in binary): {utility.permissionGradeToString(perm.grade)}<br/>
                                    grade(in str list): {utility.getPermissionLabels(perm.grade).join(" / ")}<br/>
                                </div>)}
                            </div>
                        </div>
                    </Col>
                </Row>}
            </Col>
            <Col className="item col-2 text-center">
                <button
                    className="execute notosanskr-400 font-size-16"
                    type="button"
                    onClick={async (_) => {
                        const email = document.getElementById("user-email");
                        if (!email.value.trim()) return console.log("has no email");
                        console.log("try to get user info of ", email.value.trim());
                        try {
                            const payload = await Api.getUserInfo({
                                email: email.value.trim(),
                                optPermissions: true,
                                optGroups: true
                            });
                            setUserInfo({
                                realname: payload.realname || "",
                                state: payload.state || "",
                                _id: payload._id || "",
                                name: payload.name || "",
                                push_tokens: {
                                    android: payload.push_tokens?.android || [],
                                    ios: payload.push_tokens?.ios || []
                                },
                                permissions: payload.optPermissions?.map(perm =>
                                    perm.group ? {
                                        group: payload.optGroups.find(group => group._id == perm.group).name,
                                        grade: perm.grade,
                                        grade_name: perm.grade_name,
                                    }
                                    :
                                    {
                                        group: "-None-",
                                        grade: perm.grade,
                                        grade_name: perm.grade_name
                                    }
                                )?.sort((a, b) => a.group == "-None-" ? -1 : b.group == "-None-" ? 1 : a.group.localeCompare(b.group)) || []
                            });
                        } catch (e) {
                            dgLogger.error(e)();
                            return setState({ ...state, popup: POPUP.FailedToGetUserInfo, popupTarget: e.message });
                        }
                    }}
                >
                    보내기
                </button>
            </Col>
        </Row>
        <Row className="py-2 item-row gx-0 px-2 align-items-center notosanskr-500 font-size-16">
            <Col className="item col-10">자료 사용권 신청 거절 메일 전송 (브라우저 언어에 따른 처리)</Col>
            <Col className="item col-2 text-center">
                <button
                    className="execute notosanskr-400 font-size-16"
                    type="button"
                    onClick={async (_) => {
                        try {
                            await Api.updateReportLicenseState({
                                histories: [],
                                state: utility.STATE.REJECTED,
                                reason: "test message",
                                lang: common.getLang(),
                            });
                        } catch (e) {
                            dgLogger.error(e)();
                            setState({ ...state, popup: POPUP.UpdateReportLicenseStateFailure, popupTarget: e.message });
                            return;
                        }
                    }}
                >
                    보내기
                </button>
            </Col>
        </Row>
        <Row className="py-2 item-row gx-0 px-2 align-items-center notosanskr-500 font-size-16">
            <Col className="item col-10">그룹생성 알림전송 (<span style={{ color: "red" }}>주의!!</span> 실제 관리자에게 그룹 생성 요청이 발생됨)</Col>
            <Col className="item col-2 text-center">
                <button
                    className="execute notosanskr-400 font-size-16"
                    type="button"
                    onClick={async (_) => {
                        try {
                            await Api.requestGroupCreation({
                                name: `fake-group-${generateRandomString(16)}`,
                                userName: `fake-name-${generateRandomString(16)}`,
                                contact: `fake-contact-${generateRandomString(16)}`,
                                research: `fake-research-${generateRandomString(32)}`,
                                rel_link: `http://fake-link-${generateRandomString(16)}`,
                                require_join_confirm: false,
                                about: `fake-about-${generateRandomString(128)}`,
                                lang: common.getLang()
                            });
                        } catch (e) {
                            dgLogger.error(e)();
                            setState({ ...state, popup: POPUP.GeneralError, popupTarget: e.message });
                            return;
                        }
                    }}
                >
                    보내기
                </button>
            </Col>
        </Row>
        <Row className="py-2 item-row gx-0 px-2 align-items-center notosanskr-500 font-size-16">
            <Col className="item col-10">
                <Row>
                    <Col>
                        자료 사용권 요청 알림전송 (<span style={{ color: "red" }}>주의!!</span> 실제 자료 소유자에게 사용권 요청이 발생됨)
                    </Col>
                </Row>
                <Row>
                    <Col className="ps-4 col-4">자료 id 들 (',' 로 구분)</Col>
                    <Col>
                        <InputText
                            id="report-ids"
                            defaultValue={""}
                            className="w-100"
                        />
                    </Col>
                </Row>
            </Col>
            <Col className="item col-2 text-center">
                <button
                    className="execute notosanskr-400 font-size-16"
                    type="button"
                    onClick={async (_) => {
                        const value = document.getElementById("report-ids").value;
                        const reportIDs = value.split(',').map(v => v.trim()).filter(v => v != "");
                        if (reportIDs.length == 0) return console.warn("no report ids. ignore it");

                        try {
                            await Api.requestReportLicense({
                                reportIds: reportIDs,
                                name: `fake-group-${generateRandomString(16)}`,
                                email: `fake-email-${generateRandomString(16)}`,
                                contact: `fake-contact-${generateRandomString(16)}`,
                                cause: `fake-cause-${generateRandomString(32)}`,
                                lang: common.getLang()
                            });
                        } catch (e) {
                            dgLogger.error(e)();
                            setState({ ...state, popup: POPUP.GeneralError, popupTarget: e.message });
                            return;
                        }
                    }}
                >
                    보내기
                </button>
            </Col>
        </Row>
        <Row className="py-2 item-row gx-0 px-2 align-items-center notosanskr-500 font-size-16">
            <Col className="item col-10">
                <Row>
                    <Col>
                        그룹 가입 요청 알림전송 (<span style={{ color: "red" }}>주의!!</span> 실제 그룹 매니저에게 가입 요청이 발생됨)
                    </Col>
                </Row>
                <Row>
                    <Col className="ps-4 col-4">승인 필요한 미 가입 그룹</Col>
                    <Col>
                        <SelectWrapper
                            className="w-100"
                            onChange={(e) => {
                                console.log(e);
                                setState({ ...state, groupID: e.target.value });
                            }}
                            list={notMemberedGroups.map((v) => ({ ...v, name: common.i18nGroupName(v.name) }))}
                            placeholder={""}
                        />
                    </Col>
                </Row>
            </Col>
            <Col className="item col-2 text-center">
                <button
                    className="execute notosanskr-400 font-size-16"
                    type="button"
                    onClick={async (_) => {
                        if (state.groupID == null) return console.warn("no selected group. ignore it");

                        try {
                            await Api.requestJoinGroupMember({
                                _id: state.groupID,
                                lang: common.getLang()
                            });
                        } catch (e) {
                            dgLogger.error(e)();
                            setState({ ...state, popup: POPUP.GeneralError, popupTarget: e.message });
                            return;
                        }
                    }}
                >
                    보내기
                </button>
            </Col>
        </Row>
        <Row className="py-2 item-row gx-0 px-2 align-items-center notosanskr-500 font-size-16">
            <Col className="item col-10">
                <Row className="">
                    <Col className="item col-12">지정된 사용자에게 메일 (500개) 전달. (https://temp-mail.org/ 임시 메일 주소 생성해서 테스트할것)</Col>
                </Row>
                <Row>
                    <Col className="ps-4 col-4">메일 받을 사용자(email 주소)</Col>
                    <Col>
                        <InputText
                            id="email-to"
                            defaultValue={""}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col className="ps-4 col-4">메일 제목</Col>
                    <Col>
                        <InputText
                            className="w-100"
                            id="email-title"
                            defaultValue={""}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col className="ps-4 col-4">메일 본문</Col>
                    <Col>
                        <textarea
                            style={{
                                width: "100%",
                                height: "100px"
                            }}
                            id="email-body"
                            defaultValue={""}
                            placeholder={"이것은 메일 본문입니다.\n여러줄로 출력될 수 있을까요?\n확인해 볼 필요가 있네요."}
                        />
                    </Col>
                </Row>
            </Col>
            <Col className="item col-2 text-center">
                <button
                    className="execute notosanskr-400 font-size-16"
                    type="button"
                    onClick={async (_) => {
                        try {
                            const to = document.getElementById("email-to").value;
                            const title = document.getElementById("email-title").value;
                            const body = document.getElementById("email-body").value;
                            console.log(`to : ${to}`);
                            console.log(`title : ${title}`);
                            console.log(`body : ${body}`);
                            const users = new Array(500).fill({ to, title, body });
                            await Api.sendBulkMail({ users });
                        } catch (e) {
                            dgLogger.error(e)();
                            // setState({ ...state, popup: POPUP.GeneralError, popupTarget: e.message });
                            alert(e.message);
                        }
                    }}
                >
                    보내기
                </button>
            </Col>
        </Row>
        <Row className="py-2 item-row gx-0 px-2 align-items-center notosanskr-500 font-size-16">
            <Col className="item col-10">
                <Row className="">
                    <Col className="item col-12">지정된 사용자(on Mobile App) 에게 알림 전달</Col>
                </Row>
                <Row>
                    <Col className="ps-4 col-4">알림 받을 사용자(email)</Col>
                    <Col>
                        <InputText
                            id="notification-to"
                            defaultValue={""}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col className="ps-4 col-4">알림 제목</Col>
                    <Col>
                        <InputText
                            className="w-100"
                            id="notification-title"
                            defaultValue={""}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col className="ps-4 col-4">알림 본문</Col>
                    <Col>
                        <textarea
                            style={{
                                width: "100%",
                                height: "100px"
                            }}
                            id="notification-desc"
                            defaultValue={""}
                            placeholder={"이것은 알림 본문입니다.\n여러줄로 출력될 수 있을까요?\n확인해 볼 필요가 있네요."}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col className="ps-4 col-4">알림 Extra Data (json format).<br /><span className="">optional</span></Col>
                    <Col>
                        <textarea
                            style={{
                                width: "100%",
                                height: "150px"
                            }}
                            id="notification-data"
                            defaultValue={""}
                            placeholder={"{\n  \"key\": value,\n  \"key2\": value,\n  ...\n  \"lastKey\": value\n}"}
                        />
                    </Col>
                </Row>
            </Col>
            <Col className="item col-2 text-center">
                <button
                    className="execute notosanskr-400 font-size-16"
                    type="button"
                    onClick={async (_) => {
                        try {
                            const to = document.getElementById("notification-to").value;
                            const title = document.getElementById("notification-title").value;
                            const body = document.getElementById("notification-desc").value;
                            const data = document.getElementById("notification-data").value;
                            console.log(`to : ${to}`);
                            console.log(`title : ${title}`);
                            console.log(`body : ${body}`);
                            console.log(`data : ${data}`);
                            await Api.sendNotification({
                                to,
                                title,
                                body,
                                data
                            });
                        } catch (e) {
                            dgLogger.error(e)();
                            // setState({ ...state, popup: POPUP.GeneralError, popupTarget: e.message });
                            alert(e.message);
                        }
                    }}
                >
                    보내기
                </button>
            </Col>
        </Row>
    </>;
}
