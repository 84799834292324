import { Col } from "react-bootstrap";
import { Row } from "react-bootstrap";
import "./Widgets.css";

export default function Widgets() {
  return <>
    <Row className="item-row gx-0 px-2 py-2 align-items-center notosanskr-500 font-size-18">
      <Col className="item col-8">설명 [참고. widget 의 style 일뿐, text style 이 아님]</Col>
      <Col className="item col-4 text-center">버튼</Col>
    </Row>
    <Row className="item-row gx-0 px-2 py-2 align-items-center notosanskr-500 font-size-16">
      <Col className="col-8">
        확인 버튼 (.ok)
      </Col>
      <Col className="col-4 text-center">
        <button className="ok">확인</button>
      </Col>
    </Row>
    <Row className="item-row gx-0 px-2 py-2 align-items-center notosanskr-500 font-size-16">
      <Col className="col-8">
        취소 버튼 (.cancel)
      </Col>
      <Col className="col-4 text-center">
        <button className="cancel">취소</button>
      </Col>
    </Row>
  </>;
}
