import React, { useState, useEffect, useRef, useContext } from "react";
import Editor from "../TinyEditor";
import Api from "../../Api";
import { useIsMount } from "../../common/customHook";
import common from "../../common/common";
import { AppContext } from "../../AppContext";
import { Container } from "react-bootstrap";
import "./more.css";
import { utility } from "@ocean-knight/shared";
import dgLogger from "../../common/dgLogger";
import { useTranslation } from "react-i18next";

export default function AntiEmail() {
    const { t } = useTranslation();
    const [state, setState] = useState({ viewer: true, HTMLData: "", isAdmin: false });
    const editorRef = useRef();
    const isMount = useIsMount();
    const context = useContext(AppContext);

    /**
     * 페이지 최초 접근 시 Editor에 표시 될 HTML Data를 가져와 저장
     */
    useEffect(() => {
        document.getElementById("App").className = "more";
        Api.loadAntiEmail({lang: common.getLang()})
            .then((payload) => {
                if (!isMount.current) return;
                setState((prev) => ({
                    ...prev,
                    HTMLData: common.base64ToUtf8(payload.HTMLData) || "",
                }));
            })
            .catch((e) => {
                dgLogger.error(e)();
            });
    }, [isMount]);

    /**
     * 페이지 최초 접근 시 user의 permission을 가져와 저장
     */
    useEffect(() => {
        if (context.loggedIn) {
            Api.getCurrentUserMaxPermission()
                .then((permission) => {
                    if (!isMount.current) return;
                    setState((prev) => ({
                        ...prev,
                        isAdmin: utility.hasSiteAdminPermission(permission.grade),
                    }));
                })
                .catch((e) => {
                    dgLogger.error(e)();
                });
        }
    }, [context.loggedIn, isMount]);


    /**
     * 저장을 눌렀을 때 현재 editor에 입력 된 것을 DB에 저장하는 함수
     */
    const saveAntiEmail = async () => {
        await editorRef.current?.uploadImages();
        const HTMLData = editorRef.current?.getContent();

        const payload = {
            HTMLData: common.utf8ToBase64(HTMLData) || "",
            lang: common.getLang()
        };

        // Editor에 입력 되어 HTML tag로 생성 된 문자열의 길이가 2MB를 초과하면 DB에 저장 되지 않고 alert 출력
        if (JSON.stringify(payload).length >= 2097152) {
            alert("The length of the data exceeds the limit.");
            return;
        }

        Api.saveAntiEmail(payload)
            .then((payload) => {
                setState({
                    ...state,
                    viewer: true,
                    HTMLData: HTMLData,
                });
            })
            .catch((e) => {
                dgLogger.error(e)();
            });
    };

    /**
     * Editor를 활성화 (viewr를 비활성화)
     */
    const enabledEditor = () => {
        setState({
            ...state,
            viewer: false,
        });
    };

    /**
     * Editor를 비활성화 (viewr를 활성화)
     */
    const disabledEditor = () => {
        setState({
            ...state,
            viewer: true,
        });
    };

    /**
     * 현재 상태를 보고 button을 생성하는 함수
     * @returns button tag
     */
    const createButton = () => {
        if (state.isAdmin) {
            if (state.viewer) {
                return <button onClick={enabledEditor}>{t("264")}</button>;
            } else {
                return (
                    <>
                        <button onClick={saveAntiEmail}>{t("265")}</button>
                        <button onClick={disabledEditor}>{t("80")}</button>
                    </>
                );
            }
        }
    };

    return (<>
        <Container>
            <div className="more-sub-title">
                <span className="adobe-gothic-std-75 adobe-gothic-std-40:sm c-fff more">Anti-Email</span>
                <span className="notosanskr-24b notosanskr-14b:sm c-fff">{t("11")}</span>
            </div>
            <div>
                <Editor
                    key={state.viewer}
                    ref={editorRef}
                    viewer={state.viewer}
                    HTMLData={state.HTMLData || ""} />
                {createButton()}
            </div>
        </Container>
        </>
    );
}
