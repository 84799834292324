import "./NoticeForm.css";
import { Row, Col } from "react-bootstrap";
import { MultiSelect } from "react-multi-select-component";
import common from "../../../common/common";
import { useTranslation } from "react-i18next";
import CustomCheckbox from "../../common/CustomCheckbox";
import { useState, useEffect, useCallback, useRef } from "react";
import { POPUP as GeneralPopup } from "../../../common/defines";
import dgLogger from "../../../common/dgLogger";
import Api from "../../../Api";
import { InputText } from "../../Input";
import GeneralErrorWindow from "../../common/GeneralErrorWindow";
import { useNavigate, useParams } from "react-router-dom";
import LoadingSpinner from "../../common/LoadingSpinner";
import { usePC } from "../../common/MediaQuery";
import Modal from "../../common/Modal";
import Editor from "../../TinyEditor";
import { useCallbackPrompt, useIsMount } from "../../../common/customHook";
import { utility } from "@ocean-knight/shared";

const POPUP = {
    ...GeneralPopup,
    PermissionError: 1003,
};

export const PopupLeaveConfirm = ({ onConfirm, onCancel }) => {
    const { t } = useTranslation();
    const header = <div>{t("920")}</div>;
    const body = <div>{t("921")}</div>;

    return <Modal onRequestClose={onCancel} onConfirm={onConfirm} onCancel={onCancel} header={header} body={body} overrideConfirmText={t("922")} overrideCancelText={t("80")} />;
};

export default function NoticeForm(props) {
    const isMount = useIsMount();
    const { t } = useTranslation();
    const { _id } = useParams();
    const navigate = useNavigate();
    const isPc = usePC();
    const [groups, setGroups] = useState([]);
    const [state, setState] = useState({
        popup: POPUP.None,
        popupTarget: null,
        hasFormErrors: false,
        isLoading: true,
        blockLeave: false,
        hasPermission: false,

        groups: [],
        pinned: false,
        pinnedStartAt: null,
        pinnedEndAt: null,
        title: "",
        content: "",
        sendMail: false,
    });
    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(state.blockLeave);
    const [errors, setErrors] = useState({ groups: "", startDate: "", endDate: "", title: "", content: "" });
    const editorRef = useRef();

    useEffect(() => {
        if (!isMount.current) return console.log("notice form is unmounted");

        const hasSiteAdminPermission = common.hasSiteAdminPermission();
        const hasGroupManagerPermission = common.hasGroupManagerPermission();
        const hasGroupSupervisorPermission = common.hasPermission(null, [utility.GroupSupervisorPermission]);

        if (!hasGroupManagerPermission && !hasSiteAdminPermission) {
            return setState((prev) => ({
                ...prev,
                popup: POPUP.PermissionError,
                popupTarget: t("539"),
            }));
        }

        const getGroupList = async () => {
            try {
                let payload;
                if (hasSiteAdminPermission) {
                    payload = await Api.getActiveGroupListAll();
                } else if (hasGroupManagerPermission) {
                    payload = await Api.getActiveGroupListMine();
                } else {
                    return setState((prev) => ({ ...prev, popup: POPUP.PermissionError, popupTarget: t("539") }));
                }

                const groups = payload
                    .map((group) => ({ ...group, name: common.i18nGroupName(group.name) }))
                    .sort((a, b) => {
                        const aName = a.name;
                        const bName = b.name;
                        const isKoreanA = /[ㄱ-ㅎ|가-힣]/.test(aName);
                        const isKoreanB = /[ㄱ-ㅎ|가-힣]/.test(bName);

                        // 한글이 우선순위 높도록 조정
                        if (isKoreanA && !isKoreanB) return -1;
                        if (!isKoreanA && isKoreanB) return 1;
                        // 같은 언어끼리는 localeCompare 사용하여 정렬 (한글 우선)
                        return aName.localeCompare(bName, "ko-KR");
                    });

                // 선택 가능한 그룹이 한 개일 경우, 그룹이 자동 선택됨(드롭박스 아님)

                const hasPermission = hasSiteAdminPermission || hasGroupSupervisorPermission;
                if (groups?.length == 1) {
                    setState((prev) => ({ ...prev, hasPermission, groups: [{ value: groups[0]._id, label: groups[0].name }] }));
                } else {
                    setState((prev) => ({ ...prev, hasPermission}));
                }

                setGroups(groups);
            } catch (e) {
                dgLogger.error(e)();
                setState((prev) => ({ ...prev, popup: POPUP.GeneralError, popupTarget: e.toString() }));
            }
        };

        getGroupList();
    }, [isMount, t]);

    useEffect(() => {
        if (state.hasFormErrors) {
            setState((prev) => ({ ...prev, hasFormErrors: false }));
            common.scrollToInvalidElement();
        }
    }, [state.hasFormErrors]);

    useEffect(() => {
        const getNotice = async () => {
            try {
                const payload = await Api.getNotice(_id);

                console.log("received", payload.content);

                setState((prev) => ({
                    ...prev,
                    isLoading: false,
                    groups: payload.groups.map((group) => ({ value: group.groupId, label: group.groupName })),
                    pinned: common.isCurrentTimeBetween(payload.pinnedStartAt, payload.pinnedEndAt),
                    pinnedStartAt: payload.pinnedStartAt,
                    pinnedEndAt: payload.pinnedEndAt,
                    title: payload.title,
                    content: common.base64ToUtf8(payload.content),
                    sendMail: payload.sendMail,
                }));
            } catch (e) {
                dgLogger.error(e)();
                setState((prev) => ({ ...prev, isLoading:false, popup: POPUP.GeneralError, popupTarget: e.toString() }));
            }
        };

        if (groups.length) {
            if (_id) getNotice();
            else setState((prev) => ({ ...prev, isLoading: false }));
        }
    }, [_id, groups, editorRef]);

    const isDateEmpty = useCallback((e) => {
        if (!e.target.value) e.target.required = true;
        else e.target.required = false;
    }, []);

    const isValid = useCallback(
        (elements) => {
            const [flag, errorCollection] = common.isValid(elements);

            setErrors({ ...errors, ...errorCollection });
            return flag;
        },
        [errors]
    );

    const popups = useCallback(() => {
        switch (state.popup) {
            case POPUP.GeneralError:
                return <GeneralErrorWindow message={state.popupTarget} onClick={() => navigate("/notice")} />;
            case POPUP.PermissionError:
                return <GeneralErrorWindow message={state.popupTarget} onClick={() => navigate("/Login/Signin")} />;
            case POPUP.None:
                break;
            default:
                console.log(`Can not handle ${state.popup} popup type`);
        }
    }, [navigate, state.popup, state.popupTarget]);

    /**
     * 모든 field의 값이 입력 되었는지 판단하는 함수
     * 종료일시 미정 체크 시 종료 일시는 입력 되지 않아도 통과
     *
     * @returns true or false
     */
    const validateFields = () => {
        let flag = true;
        const errorCollection = {};
        const title = document.getElementById(`title`);
        const startDate = document.getElementById(`start-date`);
        const endDate = document.getElementById(`end-date`);
        const groups = document.querySelector(".multi-select-group > .dropdown-container");
        const tinyMce = document.querySelector("div.tox-tinymce");

        common.validateInputField(errorCollection, "title", title) || (flag = false);

        if (state.pinned) {
            common.validateInputField(errorCollection, "startDate", startDate) || (flag = false);

            if (!endDate.value.trim()) {
                errorCollection.endDate = { state: false, message: t("15") };
                endDate.classList.add("invalid");
                flag = false;
            } else {
                errorCollection.endDate = { state: false, message: "" };
                endDate.classList.remove("invalid");
            }
        } else {
            startDate?.classList.remove("invalid");
            endDate?.classList.remove("invalid");
        }

        if (state.groups.length == 0) {
            groups.classList.add("invalid");
            flag = false;
        } else {
            groups.classList.remove("invalid");
        }

        const rawHtmlContent = editorRef.current?.getContent();

        if (rawHtmlContent == "") {
            tinyMce.classList.add("invalid");
            flag = false;
        } else {
            tinyMce.classList.remove("invalid");
        }

        setErrors({ ...errors, ...errorCollection });
        return flag;
    };

    const submitNotice = async () => {
        if (!validateFields()) {
            return setState({ ...state, hasFormErrors: true });
        }

        const prevBlockLeave = state.blockLeave;
        setState({ ...state, isLoading: true, blockLeave: false });

        await editorRef.current?.uploadImages();
        const rawHtmlContent = editorRef.current?.getContent();

        try {
            const payload = {
                allGroups: groups.map((group) => ({ value: group._id, label: group.name })),
                groups: state.groups,
                pinnedStartAt: state.pinnedStartAt ? common.convertToIsoDate(document.getElementById(`start-date`).value) : undefined,
                pinnedEndAt: state.pinnedEndAt ? common.convertToIsoDate(document.getElementById(`end-date`).value) : undefined,
                title: state.title || "",
                content: common.utf8ToBase64(rawHtmlContent) || "",
                sendMail: state.sendMail,
                lang: common.getLang(),
            };

            const created = _id ? await Api.updateNotice({ ...payload, _id: _id }) : await Api.postNotice(payload);

            // sessionStorage에서 prevPathname 가져오기
            const prevPathname = sessionStorage.getItem("prevPathname")?.split(",") || [];
            // 마지막 url 을 제외하고, 목록을 다시 sessionStorage에 저장
            sessionStorage.setItem("prevPathname", prevPathname.slice(0, -1));

            navigate(`/notice/${created._id}`, { replace: true });
        } catch (e) {
            dgLogger.error(e)();
            setState({ ...state, blockLeave: prevBlockLeave, popup: POPUP.GeneralError, popupTarget: e.toString() });
        } finally {
            setState({ ...state, isLoading: false, blockLeave: prevBlockLeave });
        }
    };

    return (
        <div id="notice-form">
            <LoadingSpinner isOpen={state.isLoading} />
            {showPrompt && (
                <PopupLeaveConfirm
                    onConfirm={() => confirmNavigation()}
                    onCancel={() => {
                        cancelNavigation();
                        setState((prev) => ({ ...prev, popup: POPUP.None, popupTarget: undefined }));
                    }}
                />
            )}
            {popups()}
            <Row className="header align-items-center pt-26px:sm">
                <Col>
                    <label className="notosanskr-40b notosanskr-26b:md notosanskr-20b:sm letter-spacing--1px letter-spacing--0.65px:md letter-spacing--0.5px:sm">{t("916")}</label>
                </Col>
                <Col className="col-auto">
                    <input
                        type="submit"
                        className="notosanskr-16 notosanskr-13:sm letter-spacing--0.8px letter-spacing--0.65px:sm notosanskr-13:sm c-fff submit"
                        onClick={async () => {
                            await submitNotice();
                        }}
                        id="submit"
                        value={t("924")}
                    />
                </Col>
            </Row>
            <Row className="notosanskr-400 font-size-14 align-items-center mt-25px mt-35px:md mt-15px:sm">
                <Col className="col-auto filter-option">
                        <MultiSelect
                            className="c-333 notosanskr-400 font-size-14 multi-select-group"
                            options={groups.map((group) => ({ value: group._id, label: group.name }))}
                            value={state.groups}
                            onChange={(items) => {
                                setState({ ...state, blockLeave: true, groups: items });
                            }}
                            disableSearch={true}
                            hasSelectAll={state?.hasPermission}
                            disabled={groups?.length <= 1}
                            overrideStrings={{
                                selectSomeItems: t("38"),
                                selectAll: groups?.length === 1 ? groups[0].name : t("404"),
                                selectAllFiltered: groups?.length === 1 ? groups[0].name : t("404"),
                                allItemsAreSelected: groups?.length === 1 ? groups[0].name : t("404")
                            }}
                            ClearSelectedIcon={groups?.length <= 1 ? <></> : undefined }
                        />
                </Col>
                <Col className="col col-xl-auto d-flex justify-content-end">
                    <div className="pl-50px pl-unset:md pl-unset:sm">
                        <CustomCheckbox
                            type="checkbox"
                            disabled={false}
                            onChange={(e) => {
                                setState({ ...state,
                                    blockLeave: true,
                                    pinned: e.target.checked,
                                    pinnedStartAt: e.target.checked ? Date.now() : undefined,
                                    pinnedEndAt: e.target.checked ? Date.now() : undefined,
                                 });
                            }}
                            checked={state.pinned}
                        >
                            {t("925")}
                        </CustomCheckbox>
                    </div>
                </Col>
                {!isPc && (
                    <Col className="col d-flex justify-content-end">
                        <div className="pl-50px:md pl-unset:sm">
                            {!_id && (
                                <CustomCheckbox
                                    type="checkbox"
                                    disabled={false}
                                    onChange={(e) => {
                                        setState({ ...state, blockLeave: true, sendMail: e.target.checked });
                                    }}
                                    checked={state.sendMail}
                                >
                                    {t("436")}
                                </CustomCheckbox>
                            )}
                        </div>
                    </Col>
                )}
                {(isPc || state?.pinned) && (
                    <Col className="col-lg-auto col-sm-12">
                        <div className="date-item-wrap pl-50px pl-unset:sm pl-unset:md mt-20px:md mt-15px:sm">
                            <input
                                type="date"
                                id="start-date"
                                className="notosanskr-14 letter-spacing--1.05px notosanskr-13:sm letter-spacing-unset:sm"
                                onBlur={(e) => {
                                    isDateEmpty(e);
                                    common.dateVerify(e, "start-date", "end-date");
                                    setState({ ...state, pinnedStartAt: e.target.value });
                                }}
                                onChange={(e) => {
                                    isDateEmpty(e);
                                    common.setDateRange(e, "start-date", "end-date");
                                    setState({ ...state, blockLeave: true, pinnedStartAt: e.target.value });
                                }}
                                max={common.convertToDate(new Date(state.pinnedEndAt || "9999-12-31"))}
                                name="startDate"
                                defaultValue={!state.pinned ? undefined : common.convertToDate(new Date(state.pinnedStartAt || Date.now()))}
                                disabled={!state.pinned}
                                required={false}
                            />
                            <span className="tilde nanumsquareb-17 nanumsquareb-13:sm">~</span>
                            <input
                                type="date"
                                className="notosanskr-14 letter-spacing--1.05px notosanskr-13:sm letter-spacing-unset:sm"
                                id="end-date"
                                onBlur={(e) => {
                                    isDateEmpty(e);
                                    common.dateVerify(e, "start-date", "end-date");
                                    setState({ ...state, pinnedEndAt: e.target.value });
                                }}
                                onChange={(e) => {
                                    isDateEmpty(e);
                                    common.setDateRange(e, "start-date", "end-date");
                                    setState({ ...state, blockLeave: true, pinnedEndAt: e.target.value });
                                }}
                                max="9999-12-31"
                                min={common.convertToDate(new Date(state.pinnedStartAt || Date.now()))}
                                name="endDate"
                                defaultValue={!state.pinned ? undefined : common.convertToDate(new Date(state.pinnedEndAt || Date.now()))}
                                disabled={!state.pinned}
                                required={false}
                            />
                        </div>
                    </Col>
                )}
                {isPc && (
                    <Col className="col-lg-auto pl-50px">
                        {!_id && (
                            <CustomCheckbox
                                type="checkbox"
                                disabled={false}
                                onChange={(e) => {
                                    setState({ ...state, blockLeave: true, sendMail: e.target.checked });
                                }}
                                checked={state.sendMail}
                            >
                                {t("436")}
                            </CustomCheckbox>
                        )}
                    </Col>
                )}
            </Row>
            <Row className="notosanskr-400 font-size-14 mt-20px mt-15px:sm">
                <Col className="col-12">
                    <InputText
                        id="title"
                        name="title"
                        className="title"
                        placeholder={t("275")}
                        maxLength={50}
                        onBlur={(e) => isValid([e.target])}
                        onChange={(e) => setState({ ...state, blockLeave: true, title: e.target.value })}
                        defaultValue={state.title}
                        disabled={false}
                    />
                </Col>
            </Row>
            <Row className="mt-20px mt-15px:sm">
                <Col className="col-12">
                    {(_id ? state.content : true) && <Editor ref={editorRef} viewer={false} HTMLData={state.content || ""} onDirty={() => setState({ ...state, blockLeave: true })} />}
                </Col>
            </Row>
        </div>
    );
}
