import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { POPUP as GeneralPopup } from "../../../common/defines";
import GeneralErrorWindow from "../../common/GeneralErrorWindow";
import { useNavigate } from "react-router-dom";
import { PopupGroupCreationReject, PopupGroupCreationRejectReason } from "../ApproveGroupCreation";
import { PopupDataUseReject, PopupDataUseRejectReason } from "../ApproveDataUseRequest";
import { PopupGroupJoinReject, PopupGroupJoinRejectReason } from "../ApproveGroupMember";
import { PopupRequestedCreateGroup } from "../RequestGroupCreation";
import { PopupWithdrawSiteMemberRequest, PopupWithdrawSiteMemberRequested } from "../MyInfo";
import { PopupTermWarning } from "../../Login/Signup";
import { PopupRemoveReport } from "../../common/RemoveWindow";
import { PopupRequestedReportLicense, PopupRequestReportLicense } from "../../common/RequestDataUseWindow";
import { UpdateMemberGrade } from "../ManageMember/UpdateMemberGradeWindow";
import { PopupJoinGroupApproved, PopupJoinGroupRejectReason, PopupRequestedJoinGroup, PopupRequestJoinGroup } from "../../common/RequestGroupMemberWindow";
import GroupInfoApplicationWindow from "../../common/GroupInfoApplicationWindow";
import SiteMemberInfoWindow from "../ManageMember/SiteMemberInfoWindow";
import PostcodeWindow from "../../PostcodeWindow";
import { RequestMailCodeWindow } from "../../common/MailAuthenticationWindow";
import { LinkCopyWindow } from "../../common/LinkCopyWindow";
import AskQuestion from "../../More/AskQuestion";
import LoginRecommendWindow from "../../common/LoginRecommendWindow";
import GroupParticipationWindow from "../../common/GroupParticipationWindow";
import CheckForTempReportWindow from "../../common/CheckForTempReportWindow";
import { DynamicFormNoModification, DynamicFormRequest, DynamicFormRequested } from "../../common/DynamicFormWindow";
import ImageUploadPendingWindow from "../../common/ImageUploadPendingWindow";
import { PopupValidateFile, PopupValidateImage } from "../../common/ValidateModal";
import { useTranslation } from "react-i18next";
import { useUserinfo } from "../../../common/customHook";
import Api from "../../../Api";
import { utility } from "@ocean-knight/shared";

const POPUP = {
    ...GeneralPopup,
    RemoveGroupFailure: 1005,
    PopupRequestJoinGroup: 1006,
    PopupRequestedJoinGroup: 1007,
    PopupJoinGroupRejectReason: 1008,
    LinkCopyWindow: 1009,
    GeneralErrorWindow: 1010,
    AskQuestion: 1011,
    LoginRecommendWindow: 1012,
    GroupParticipationWindow: 1013,
    CheckForTempReportWindow: 1014,
    DynamicFormRequest: 1015,
    DynamicFormRequested: 1016,
    DynamicFormNoModification: 1017,
    ImageUploadPendingWindow: 1018,
    PopupValidateImage: 1019,
    PopupValidateFile: 1020,
    RequestMailCodeWindow: 1021,
    PostcodeWindow: 1022,
    SiteMemberInfoWindow: 1023,
    PopupRequestReportLicense: 1024,
    GroupInfoApplicationWindow: 1025,
    PopupJoinGroupApproved: 1026,
    UpdateMemberGrade: 1027,
    PopupRequestedReportLicense: 1028,
    PopupRemoveReport: 1029,
    PopupRequestedCreateGroup: 1030,
    PopupTermWarning: 1031,
    PopupWithdrawSiteMemberRequest: 1032,
    PopupWithdrawSiteMemberRequested: 1033,
    PopupGroupJoinReject: 1034,
    PopupGroupJoinRejectReason: 1035,
    PopupDataUseRejectReason: 1036,
    PopupDataUseReject: 1037,
    PopupGroupCreationReject: 1038,
    PopupGroupCreationRejectReason: 1039,
};

export default function Modals() {
    const [state, setState] = useState({
        popup: POPUP.None,
        popupTarget: null,
    });
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { userInfo } = useUserinfo();

    const popups = () => {
        switch (state.popup) {
            case POPUP.PopupGroupCreationReject:
                return <PopupGroupCreationReject
                    onRequestClose={() => {
                        console.log("onRequestClose called");
                        setState({ ...state, popup: POPUP.None, popupTarget: null });
                    }}
                    onConfirm={() => {
                        console.log("onConfirm called");
                        setState({ ...state, popup: POPUP.None, popupTarget: null });
                    }}
                    onCancel={() => {
                        console.log("onCancel called");
                        setState({ ...state, popup: POPUP.None, popupTarget: null });
                    }}
                />;
            case POPUP.PopupGroupCreationRejectReason:
                return <PopupGroupCreationRejectReason
                    onRequestClose={() => {
                        console.log("onRequestClose called");
                        setState({ ...state, popup: POPUP.None, popupTarget: null });
                    }}
                    onConfirm={() => {
                        console.log("onConfirm called");
                        setState({ ...state, popup: POPUP.None, popupTarget: null });
                    }}
                    reason="test reason"
                />;
            case POPUP.PopupDataUseReject:
                return <PopupDataUseReject
                    onRequestClose={() => {
                        console.log("onRequestClose called");
                        setState({ ...state, popup: POPUP.None, popupTarget: null });
                    }}
                    onConfirm={() => {
                        console.log("onConfirm called");
                        setState({ ...state, popup: POPUP.None, popupTarget: null });
                    }}
                    onCancel={() => {
                        console.log("onCancel called");
                        setState({ ...state, popup: POPUP.None, popupTarget: null });
                    }}
                />;
            case POPUP.PopupDataUseRejectReason:
                return <PopupDataUseRejectReason
                    onRequestClose={() => {
                        console.log("onRequestClose called");
                        setState({ ...state, popup: POPUP.None, popupTarget: null });
                    }}
                    onConfirm={() => {
                        console.log("onConfirm called");
                        setState({ ...state, popup: POPUP.None, popupTarget: null });
                    }}
                    reason="test reason"
                />;
            case POPUP.PopupGroupJoinRejectReason:
                return <PopupGroupJoinRejectReason
                    onRequestClose={() => {
                        console.log("onRequestClose called");
                        setState({ ...state, popup: POPUP.None, popupTarget: null });
                    }}
                    onConfirm={() => {
                        console.log("onConfirm called");
                        setState({ ...state, popup: POPUP.None, popupTarget: null });
                    }}
                    reason="test reason"
                />;

            case POPUP.PopupGroupJoinReject:
                return <PopupGroupJoinReject
                    onRequestClose={() => {
                        console.log("onRequestClose called");
                        setState({ ...state, popup: POPUP.None, popupTarget: null });
                    }}
                    onConfirm={() => {
                        console.log("onConfirm called");
                        setState({ ...state, popup: POPUP.None, popupTarget: null });
                    }}
                    onCancel={() => {
                        console.log("onCancel called");
                        setState({ ...state, popup: POPUP.None, popupTarget: null });
                    }}
                />;

            case POPUP.PopupRequestedCreateGroup:
                return <PopupRequestedCreateGroup
                    onClose={() => {
                        console.log("onClose called");
                        setState({ ...state, popup: POPUP.None, popupTarget: null });
                    }} />;
            case POPUP.PopupWithdrawSiteMemberRequest:
                return <PopupWithdrawSiteMemberRequest
                    onRequestClose={() => {
                        console.log("onRequestClose called");
                        setState({ ...state, popup: POPUP.None, popupTarget: null });
                    }}
                    onConfirm={() => {
                        console.log("onConfirm called");
                        setState({ ...state, popup: POPUP.None, popupTarget: null });
                    }}
                    onCancel={() => {
                        console.log("onCancel called");
                        setState({ ...state, popup: POPUP.None, popupTarget: null });
                    }}
                />;
            case POPUP.PopupWithdrawSiteMemberRequested:
                return <PopupWithdrawSiteMemberRequested
                    onRequestClose={() => {
                        console.log("onRequestClose called");
                        setState({ ...state, popup: POPUP.None, popupTarget: null });
                    }}
                    onConfirm={() => {
                        console.log("onConfirm called");
                        setState({ ...state, popup: POPUP.None, popupTarget: null });
                    }}
                />;
            case POPUP.PopupTermWarning:
                return (
                    <PopupTermWarning
                        onRequestClose={() => {
                            console.log("onRequestClose called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                        onConfirm={() => {
                            console.log("onConfirm called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }} />
                );
            case POPUP.PopupRemoveReport:
                return (
                    <PopupRemoveReport
                        onConfirm={() => {
                            console.log("onConfirm called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                        onCancel={() => {
                            console.log("onCancel called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                    />
                );
            case POPUP.PopupRequestedReportLicense:
                return (
                    <PopupRequestedReportLicense
                        onRequestClose={() => {
                            console.log("onRequestClose called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                        onConfirm={() => {
                            console.log("onConfirm called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                    />
                );
            case POPUP.UpdateMemberGrade:
                const users = state.popupTarget.users;
                const grades = state.popupTarget.grades;
                return (
                    <UpdateMemberGrade
                        users={users}
                        grades={grades}
                        title={t("434")}
                        onCancel={() => {
                            console.log("onCancel called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                        className={""}
                        onConfirmed={(_, __) => {
                            console.log("onConfirmed called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                    />
                );
            case POPUP.PopupJoinGroupApproved:
                return (
                    <PopupJoinGroupApproved
                        onClick={() => {
                            console.log("onClick called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                    />
                );
            case POPUP.GroupInfoApplicationWindow:
                return (
                    <GroupInfoApplicationWindow
                        group={state.popupTarget}
                        onClick={() => {
                            console.log("onClick called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                    />
                );
            case POPUP.PopupRequestReportLicense:
                return (
                    <PopupRequestReportLicense
                        onSubmit={() => {
                            console.log("onSubmit called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                        onCancel={() => {
                            console.log("onCancel called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                    />
                );
            case POPUP.SiteMemberInfoWindow:
                return (
                    <SiteMemberInfoWindow
                        user={state.popupTarget}
                        groups={[]}
                        onCancel={() => {
                            console.log("onCancel called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                    />
                );
            case POPUP.PostcodeWindow:
                return (
                    <PostcodeWindow
                        onComplete={(_address1, _address2) => {
                            console.log("onComplete called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                        onCancel={() => {
                            console.log("onCancel called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                    />
                );
            case POPUP.RequestMailCodeWindow:
                return (
                    <RequestMailCodeWindow
                        onRequestClose={() => {
                            console.log("onRequestClose called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                        onConfirm={() => {
                            console.log("onConfirm called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                        onCancel={() => {
                            console.log("onCancel called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                        mail={"testuser@oceancloud.co.kr"}
                    />
                );
            case POPUP.PopupValidateFile:
                return (
                    <PopupValidateFile
                        maxFiles={20}
                        maxSizeInMB={2000}
                        onCancel={() => {
                            console.log("onCancel called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                    />
                );
            case POPUP.PopupValidateImage:
                return (
                    <PopupValidateImage
                        onCancel={() => {
                            console.log("onCancel called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                    />
                );
            case POPUP.ImageUploadPendingWindow:
                return (
                    <ImageUploadPendingWindow
                        onConfirm={() => {
                            console.log("onConfirm called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                    />
                );
            case POPUP.DynamicFormNoModification:
                return (
                    <DynamicFormNoModification
                        onConfirm={() => {
                            console.log("onConfirm called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                    />
                );
            case POPUP.DynamicFormRequested:
                return (
                    <DynamicFormRequested
                        onConfirm={() => {
                            console.log("onConfirm called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                    />
                );
            case POPUP.DynamicFormRequest:
                return (
                    <DynamicFormRequest
                        onConfirm={() => {
                            console.log("onConfirm called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                        onCancel={() => {
                            console.log("onCancel called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                    />
                );
            case POPUP.CheckForTempReportWindow:
                return (
                    <CheckForTempReportWindow
                        overrideOnClick={() => {
                            console.log("overrideOnClick called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                        onCancel={() => {
                            console.log("onCancel called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                    />
                );
            case POPUP.GroupParticipationWindow:
                return (
                    <GroupParticipationWindow
                        onConfirm={() => {
                            console.log("onConfirm called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                        onCancel={() => {
                            console.log("onCancel called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                    />
                );
            case POPUP.LoginRecommendWindow:
                return (
                    <LoginRecommendWindow
                        onConfirm={() => {
                            console.log("onConfirm called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                        onCancel={() => {
                            console.log("onCancel called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                    />
                );
            case POPUP.AskQuestion:
                return (
                    <AskQuestion
                        user={userInfo}
                        onSend={() => {
                            console.log("onSend called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                        onCancel={() => {
                            console.log("onCancel called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                    />
                );
            case POPUP.LinkCopyWindow:
                return (
                    <LinkCopyWindow
                        onConfirm={() => {
                            console.log("onConfirm called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                    />
                );
            case POPUP.PopupJoinGroupRejectReason:
                return (
                    <PopupJoinGroupRejectReason
                        state_reason={state.popupTarget}
                        onClick={() => {
                            console.log("onClick called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                    />
                );
            case POPUP.PopupRequestJoinGroup:
                return (
                    <PopupRequestJoinGroup
                        onConfirm={() => {
                            console.log("onConfirm called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                        onCancel={() => {
                            console.log("onCancel called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                        groupName={"Example Group"}
                    />
                );
            case POPUP.PopupRequestedJoinGroup:
                return (
                    <PopupRequestedJoinGroup
                        require_join_confirm={state.popupTarget.require_join_confirm}
                        onClick={() => {
                            console.log("onClick called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                    />
                );
            case POPUP.GeneralErrorWindow:
                return (
                    <GeneralErrorWindow
                        message={state.popupTarget}
                        onClick={() => {
                            console.log("onClick called");
                            setState({ ...state, popup: POPUP.None, popupTarget: null });
                        }}
                    />
                );
            case POPUP.GeneralError:
                return (
                    <GeneralErrorWindow
                        message={state.popupTarget}
                        onClick={() => {
                            navigate("/");
                        }}
                    />
                );
            case POPUP.UpdateReportLicenseStateFailure:
            case POPUP.RemoveGroupFailure:
                return (
                    <GeneralErrorWindow
                        message={state.popupTarget}
                        onClick={() => {
                            setState({ ...state, popup: POPUP.None, popupTarget: undefined });
                        }}
                    />
                );
            case POPUP.None:
                break;
            default:
                console.log(`un-handled popup (${state.popup})`);
                break;
        }
    };

    const ModalTableRow = ({ desc, onClick }) => {
        return (<Row className="item-row gx-0 px-2 py-2 align-items-center notosanskr-500 font-size-16">
            <Col className="item col-10">{desc}</Col>
            <Col className="item col-2 text-center">
                <button
                    className="execute notosanskr-400 font-size-16"
                    type="button"
                    onClick={onClick}
                >
                    열기
                </button>
            </Col>
        </Row>);
    };

    return <>
        {popups()}
        <Row className="item-row gx-0 px-2 py-2 align-items-center notosanskr-500 font-size-18">
            <Col className="item col-10">설명 (기능 동작 X)</Col>
            <Col className="item col-2 text-center">실행</Col>
        </Row>

        <ModalTableRow desc={"PopupGroupCreationReject"}
            onClick={(_) => setState({ ...state, popup: POPUP.PopupGroupCreationReject, popupTarget: undefined })} />
        <ModalTableRow desc={"PopupGroupCreationRejectReason"}
            onClick={(_) => setState({ ...state, popup: POPUP.PopupGroupCreationRejectReason, popupTarget: undefined })} />
        <ModalTableRow desc={"PopupDataUseReject"}
            onClick={(_) => setState({ ...state, popup: POPUP.PopupDataUseReject, popupTarget: undefined })} />
        <ModalTableRow desc={"PopupDataUseRejectReason"}
            onClick={(_) => setState({ ...state, popup: POPUP.PopupDataUseRejectReason, popupTarget: undefined })} />
        <ModalTableRow desc={"PopupGroupJoinReject"}
            onClick={(_) => setState({ ...state, popup: POPUP.PopupGroupJoinReject, popupTarget: undefined })} />
        <ModalTableRow desc={"PopupGroupJoinRejectReason"}
            onClick={(_) => setState({ ...state, popup: POPUP.PopupGroupJoinRejectReason, popupTarget: undefined })} />
        <ModalTableRow desc={"PopupRequestedCreateGroup"}
            onClick={(_) => setState({ ...state, popup: POPUP.PopupRequestedCreateGroup, popupTarget: undefined })} />
        <ModalTableRow desc={"PopupWithdrawSiteMemberRequest"}
            onClick={(_) => setState({ ...state, popup: POPUP.PopupWithdrawSiteMemberRequest, popupTarget: undefined })} />
        <ModalTableRow desc={"PopupWithdrawSiteMemberRequested"}
            onClick={(_) => setState({ ...state, popup: POPUP.PopupWithdrawSiteMemberRequested, popupTarget: undefined })} />
        <ModalTableRow desc={"PopupTermWarning"}
            onClick={(_) => setState({ ...state, popup: POPUP.PopupTermWarning, popupTarget: undefined })} />
        <ModalTableRow desc={"PopupRemoveReport"}
            onClick={(_) => setState({ ...state, popup: POPUP.PopupRemoveReport, popupTarget: undefined })} />
        <ModalTableRow desc={"PopupRequestedReportLicense (자료 사용권 신청 완료)"}
            onClick={(_) => setState({ ...state, popup: POPUP.PopupRequestedReportLicense, popupTarget: undefined })} />
        <ModalTableRow desc={"UpdateMemberGrade (그룹원 등급 변경)"}
            onClick={async (_) => {
                const groupListPayload = await Api.getFilterGroupList();
                console.log(groupListPayload[0]._id);
                const groupMemberPayload = await Api.getFilteredGroupMember({ groups: [groupListPayload[0]._id] });
                console.log(groupListPayload[0].permissions);
                const popupTarget = { users: groupMemberPayload.users, grades: groupListPayload[0].permissions };
                setState({ ...state, popup: POPUP.UpdateMemberGrade, popupTarget: popupTarget });
            }} />
        <ModalTableRow desc={"GroupInfoApplicationWindow (그룹 생성 신청서)"}
            onClick={async (_) => {
                const payload = await Api.getGroupHistoryAll({
                    states: [utility.STATE.ACQUIRED],
                    currentPage: 0,
                    itemsCountPerPage: 20,
                    sort: { register_date: -1 },
                });
                const reviews = payload.histories;
                setState({ ...state, popup: POPUP.GroupInfoApplicationWindow, popupTarget: await Api.getGroupHistoryItem(reviews[0]._id) });
            }} />
        <ModalTableRow desc={"PopupRequestReportLicense (자료 사용권 신청서)"}
            onClick={(_) => setState({ ...state, popup: POPUP.PopupRequestReportLicense, popupTarget: undefined })} />
        <ModalTableRow desc={"SiteMemberInfoWindow (회원 정보)"}
            onClick={async (_) => {
                const userInfo = await Api.getUserInfo({ _id: sessionStorage.getItem("_id") });
                setState({ ...state, popup: POPUP.SiteMemberInfoWindow, popupTarget: userInfo });
            }} />
        <ModalTableRow desc={"PostcodeWindow (주소 검색)"}
            onClick={(_) => setState({ ...state, popup: POPUP.PostcodeWindow, popupTarget: undefined })} />
        <ModalTableRow desc={"RequestMailCodeWindow (메일 인증)"}
            onClick={(_) => setState({ ...state, popup: POPUP.RequestMailCodeWindow, popupTarget: undefined })} />
        <ModalTableRow desc={"PopupValidateFile"}
            onClick={(_) => setState({ ...state, popup: POPUP.PopupValidateFile, popupTarget: undefined })} />
        <ModalTableRow desc={"PopupValidateImage (파일 첨부 실패)"}
            onClick={(_) => setState({ ...state, popup: POPUP.PopupValidateImage, popupTarget: undefined })} />
        <ModalTableRow desc={"ImageUploadPendingWindow"}
            onClick={(_) => setState({ ...state, popup: POPUP.ImageUploadPendingWindow, popupTarget: undefined })} />
        <ModalTableRow desc={"DynamicFormNoModification (입력폼 변경)"}
            onClick={(_) => setState({ ...state, popup: POPUP.DynamicFormNoModification, popupTarget: undefined })} />
        <ModalTableRow desc={"DynamicFormRequested (입력폼 변경)"}
            onClick={(_) => setState({ ...state, popup: POPUP.DynamicFormRequested, popupTarget: undefined })} />
        <ModalTableRow desc={"DynamicFormRequest (입력폼 변경)"}
            onClick={(_) => setState({ ...state, popup: POPUP.DynamicFormRequest, popupTarget: undefined })} />
        <ModalTableRow desc={"CheckForTempReportWindow (임시 저장된 자료가 있습니다)"}
            onClick={(_) => setState({ ...state, popup: POPUP.CheckForTempReportWindow, popupTarget: undefined })} />
        <ModalTableRow desc={"GroupParticipationWindow (그룹 참여)"}
            onClick={(_) => setState({ ...state, popup: POPUP.GroupParticipationWindow, popupTarget: undefined })} />
        <ModalTableRow desc={"LoginRecommendWindow"}
            onClick={(_) => setState({ ...state, popup: POPUP.LoginRecommendWindow, popupTarget: undefined })} />
        <ModalTableRow desc={"AskQuestion"}
            onClick={(_) => setState({ ...state, popup: POPUP.AskQuestion, popupTarget: undefined })} />
        <ModalTableRow desc={"GeneralErrorWindow"}
            onClick={(_) => setState({ ...state, popup: POPUP.GeneralErrorWindow, popupTarget: "테스트 에러 메세지 입니다." })} />
        <ModalTableRow desc={"LinkCopyWindow"}
            onClick={(_) => setState({ ...state, popup: POPUP.LinkCopyWindow, popupTarget: undefined })} />
        <ModalTableRow desc={"PopupJoinGroupApproved (그룹 참여 승인 완료)"}
            onClick={(_) => setState({ ...state, popup: POPUP.PopupJoinGroupApproved, popupTarget: undefined })} />
        <ModalTableRow desc={"PopupJoinGroupRejectReason (그룹 참여 거절)"}
            onClick={(_) => setState({ ...state, popup: POPUP.PopupJoinGroupRejectReason, popupTarget: "테스트니까. 거부합니다." })} />
        <ModalTableRow desc={"PopupRequestedJoinGroup (그룹 참여 승인 완료)"}
            onClick={(_) => setState({ ...state, popup: POPUP.PopupRequestedJoinGroup, popupTarget: { require_join_confirm: false } })} />
        <ModalTableRow desc={"PopupRequestedJoinGroup (그룹 참여 신청 완료)"}
            onClick={(_) => setState({ ...state, popup: POPUP.PopupRequestedJoinGroup, popupTarget: { require_join_confirm: true } })} />
        <ModalTableRow desc={"PopupRequestJoinGroup (그룹 참여 신청)"}
            onClick={(_) => setState({ ...state, popup: POPUP.PopupRequestJoinGroup, popupTarget: undefined })} />
    </>;
}
