import React, { useEffect, useState } from "react";
import GroupMemberGrade from "./Group/GroupMemberGrade";
import UpdateGroupInformation from "./Group/UpdateGroupInformation";
import { POPUP } from "../../common/defines";
import Api from "../../Api";
import GeneralErrorWindow from "../common/GeneralErrorWindow";
import { useNavigate } from "react-router-dom";
import common from "../../common/common";
import { Row, Col } from "react-bootstrap";
import "./Group.css";
import Modal from "../common/Modal";
import DynamicForm from "../common/DynamicForm";
import dgLogger from '../../common/dgLogger';
import { Trans, useTranslation } from "react-i18next";
import { utility } from "@ocean-knight/shared";

const ID = {
    groupSelect: "group-manage-group-select",
    removeGroupSelect: "group-manage-remove-group-select",
};

const CONTENT = {
    None: 0,
    UpdateGroupInformation: 1,
    SetGroupMemberGrade: 2,
    SetUpdateReportForm: 3,
};

export default function Group() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [state, setState] = useState({
        popup: POPUP.None,
        popupTarget: null,
        content: CONTENT.None,
        contentTarget: null,
        groups: [],
    });

    useEffect(() => {
        const hasGroupManagerPermission = common.hasGroupManagerPermission();
        const hasSiteAdminPermission = common.hasSiteAdminPermission();
        if (!hasGroupManagerPermission && !hasSiteAdminPermission) {
            setState((prev) => ({
                ...prev,
                popup: POPUP.GeneralError,
                popupTarget: t("539"),
            }));
            return;
        }

        const getGroupList = async () => {
            try {
                let payload;
                if (hasSiteAdminPermission) {
                    payload = await Api.getActiveGroupListAll();
                } else if (hasGroupManagerPermission) {
                    payload = await Api.getActiveGroupListMine();
                } else {
                    setState((prev) => ({ ...prev, popup: POPUP.GeneralError, popupTarget: t("539") }));
                    return;
                }

                const contentTarget = payload.length === 1 ? payload[0] : null;
                setState((prev) => ({
                    ...prev,
                    groups: payload,
                    contentTarget: contentTarget,
                }));
            } catch (e) {
                dgLogger.error(e)();
                setState((prev) => ({ ...prev, popup: POPUP.GeneralError, popupTarget: e.toString() }));
            }
        };

        getGroupList();
    }, [t]);

    const content = () => {
        const content = parseInt(state.content);
        if (content === CONTENT.UpdateGroupInformation) {
            return <UpdateGroupInformation key={state.contentTarget._id} _id={state.contentTarget._id} />;
        };
        if (content === CONTENT.SetGroupMemberGrade) return <GroupMemberGrade key={state.contentTarget._id} _id={state.contentTarget._id} />;
        if (content === CONTENT.SetUpdateReportForm) return <DynamicForm key={state.contentTarget?.report_form_id} report_form_id={state.contentTarget?.report_form_id} group_id={state.contentTarget._id} />;
    };

    const selectOptions = (array) => {
        return array.map((data, index) => (
            <option key={index} value={data.id} selected={array.length === 1 || data.id === state.contentTarget?._id}>
                {data.value}
            </option>
        ));
    };

    const popupConfirmGroupRemoval = () => {
        const header = <div>{t("499")}</div>;
        const body = <div>{t("500")}</div>;

        return (
            <Modal
                onRequestClose={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
                onConfirm={() => {
                    const reason = document.getElementById("group-remove-reason").value;
                    const _id = state.popupTarget._id;
                    Api.removeGroup({
                        _id: _id,
                        reason: reason,
                        lang: common.getLang()
                    })
                        .then(() => {
                            const groups = state.groups.filter((group) => group._id !== _id);
                            setState({
                                ...state,
                                popup: POPUP.None,
                                popupTarget: null,
                                content: groups.length ? state.content : CONTENT.None,
                                contentTarget: groups.length ? groups[0] : null,
                                groups: groups,
                            });
                        })
                        .catch((e) => {
                            dgLogger.error(e)();
                            setState({ ...state, popup: POPUP.GeneralError, popupTarget: e.toString() });
                        });
                }}
                onCancel={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
                header={header}
                body={body}
                overrideConfirmText={t("465")}
                textareaId="group-remove-reason"
                maxLength={500}
            />
        );
    };

    const popups = () => {
        if (state.popup === POPUP.GroupRemovalConfirm) return popupConfirmGroupRemoval();
        if (state.popup === POPUP.GeneralError)
            return (
                <GeneralErrorWindow
                    message={state.popupTarget}
                    onClick={() => {
                        navigate("/");
                    }}
                />
            );
    };

    return (
        <div id="manage-group">
            {popups()}

            <Row className="gx-0 page-title">
                <Col className="col-auto notosanskr-600 font-size-40 notosanskr-20b:sm first">
                    {t("351")}
                </Col>
            </Row>

            <div className="filter-wrap">
                <Row className="gx-0 align-items-center">
                    <Col className="group col-auto notosanskr-500 font-size-18 c-333">
                        {t("456")}
                    </Col>
                    <Col className="group-item col-auto notosanskr-400 font-size-14">
                        <select
                            required
                            id={ID.groupSelect}
                            name="groups"
                            onChange={(e) => {
                                if (state.groups.length === 0) {
                                    dgLogger.warn("has no group")();
                                    return;
                                }
                                const _id = e.target.value;
                                const group = state.groups.find((group) => group._id === _id);

                                setState({ ...state, contentTarget: group });
                            }}
                            disabled={state.groups.length === 1}
                        >
                            {state.groups.length > 1 &&
                                <option value="" disabled selected>
                                    {t("255")}
                                </option>
                            }
                            {selectOptions(state.groups.map((group) => ({ id: group._id, value: common.i18nGroupName(group.name), })))}
                        </select>
                    </Col>

                    <Col className="modify col-auto notosanskr-500 font-size-18 c-333">
                        {t("457")}{" "}
                    </Col>
                    <Col className="modify-item col-auto notosanskr-400 font-size-14">
                        <select
                            value={state.content || CONTENT.None}
                            disabled={state.contentTarget ? false : true}
                            name="modify"
                            onChange={(e) => {
                                if (state.groups.length === 0) {
                                    // dgLogger.warn("has no group")();
                                    return;
                                }
                                const selectedOption = document.getElementById(ID.groupSelect);
                                const _id = selectedOption.options[selectedOption.selectedIndex].value;
                                let group = state.groups.find((group) => group._id === _id);

                                setState({ ...state, content: e.target.value, contentTarget: group });
                            }}
                        >
                            <option value={CONTENT.None} disabled selected>
                                {t("672")}
                            </option>
                            <option value={CONTENT.UpdateGroupInformation}>
                                {t("214").replace("<tag>", "").replace("</tag>", "")}
                            </option>
                            <option value={CONTENT.SetGroupMemberGrade}>
                                {t("458")}
                            </option>
                            <option value={CONTENT.SetUpdateReportForm}>
                                {t("459")}
                            </option>
                        </select>
                    </Col>
                    {common.hasPermission(null, [
                        utility.SiteAdminPermission,
                        utility.GroupSupervisorPermission
                    ]) &&
                        <Col className="delete col-auto notosanskr-400 font-size-16">
                            <button
                                disabled={(state.contentTarget && !state.contentTarget?.built_in) ? false : true}
                                onClick={() => {
                                    if (state.groups.length === 0) {
                                        // dgLogger.warn("has no group")();
                                        return;
                                    }
                                    setState({ ...state, popup: POPUP.GroupRemovalConfirm, popupTarget: state.contentTarget });
                                }}
                            >
                                {t("499")}
                            </button>
                        </Col>
                    }
                </Row>
            </div>
            <div key={state.contentTarget?._id}>
                {content()}
            </div>
        </div>
    );
}
