import React, { useEffect, useState } from "react";
import { POPUP as GeneralPopup } from "../../common/defines";
import { useNavigate } from "react-router-dom";
import GeneralErrorWindow from "../common/GeneralErrorWindow";
import { Row, Col, Tabs, Tab } from "react-bootstrap";
import "./Maintenance.css";
import Modals from "./Maintenance/Modals";
import Events from "./Maintenance/Events";
import Widgets from "./Maintenance/Widgets";
import common from "../../common/common";
import { useIsMount } from "../../common/customHook";

const TAB_ID = {
    modals: "maint-modals",
    events: "maint-events",
    widgets: "maint-widgets",
    notifications: "maint-notifications"
};

const POPUP = {
    ...GeneralPopup,
    UpdateReportLicenseStateFailure: 1004,
};

export default function Maintenance() {
    const [state, setState] = useState({
        popup: POPUP.None,
        popupTarget: null,
        activeTab: TAB_ID.widgets, // 현재 tab
    });

    const navigate = useNavigate();
    const isMount = useIsMount();

    useEffect(() => {
        const hasPermission = common.hasSiteAdminPermission();
        if (!hasPermission) {
            if (!isMount.current) return;
            setState((prev) => ({
                ...prev,
                popup: POPUP.GeneralError,
                popupTarget: `현재 권한으로는 접근할 수 없습니다.`,
            }));
            return;
        }
    }, [isMount]);

    const popups = () => {
        switch (state.popup) {
            case POPUP.GeneralError:
                return (
                    <GeneralErrorWindow
                        message={state.popupTarget}
                        onClick={() => {
                            navigate("/");
                        }}
                    />
                );
            case POPUP.UpdateReportLicenseStateFailure:
            case POPUP.None:
                break;
            default:
                console.log(`un-handled popup (${state.popup})`);
                break;
        }
    };

    return (
        <div id="maintenance">
            {popups()}

            <Row className="gx-0 page-title">
                <Col className="col-auto notosanskr-600 font-size-40 notosanskr-20b:sm first">Development</Col>
                <Col className="notosanskr-400 font-size-26 notosanskr-14:sm c-666 second d-table">
                    <div className="d-table-cell align-bottom">동작 확인</div>
                </Col>
            </Row>

            <Tabs
                activeKey={state.activeTab}
                id="uncontrolled-tab"
                className="uncontrolled-tab"
                onSelect={(k) => {
                    setState({ ...state, activeTab: k });
                }}
            >
                <Tab className={TAB_ID.widgets} eventKey={TAB_ID.widgets} title="위젯">
                    <Widgets />
                </Tab>
                <Tab className={TAB_ID.modals} eventKey={TAB_ID.modals} title="팝업">
                    <Modals />
                </Tab>
                <Tab className={TAB_ID.events} eventKey={TAB_ID.events} title="이벤트">
                    <Events />
                </Tab>
            </Tabs>
        </div>
    );
}
