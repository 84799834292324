import React, { useEffect, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Api from "../Api";
import { AppContext } from "../AppContext";
import common from "../common/common";
import { Container, Row, Col } from "react-bootstrap";
import { useNotification, useUserinfo } from "../common/customHook";
import "./Navigation.css";
import { POPUP as GeneralPopup } from "../common/defines";
import AskQuestion from "./More/AskQuestion";
import GeneralErrorWindow from "./common/GeneralErrorWindow";
import LoginRecommendWindow from "./common/LoginRecommendWindow";
import GroupParticipationWindow from "./common/GroupParticipationWindow";
import { Mobile, TabletOrPC } from "./common/MediaQuery";
import CheckForTempReportWindow from "./common/CheckForTempReportWindow";
import { Trans, useTranslation } from "react-i18next";
import { useIsMount } from "../common/customHook";
import { isRunOnMobileAppWebview, registerMobileAppWebviewMessageHandler, sendMessageToMobileAppWebview, setHandleMobileAppWebviewMessages, unregisterMobileAppWebviewMessageHandler } from "./common/RNBridge";

const POPUP = {
    ...GeneralPopup,
    AskQuestion: 1001,
    LoginRecommend : 1002,
    GroupParticipation: 1003,
    CheckForTempReport: 1004,
};

export default function Navigation(props) {
    const context = useContext(AppContext);
    const navigate = useNavigate();
    const isMount = useIsMount();
    const { notifications } = useNotification();
    const { t } = useTranslation();
    const { userInfo } = useUserinfo();
    const [state, setState] = useState({
        user: {},
        avatarUrl: null,
        popup: POPUP.None,
    });

    useEffect(() => {
        setHandleMobileAppWebviewMessages("register-push-notification", ({_id, deviceToken, platform}) => {
            Api.registerNotification({
                _id: _id,
                token: deviceToken,
                platform: platform
            }).catch(e => console.warn(e));
        });
        registerMobileAppWebviewMessageHandler();
        return () => {
            setHandleMobileAppWebviewMessages("register-push-notification", undefined);
            unregisterMobileAppWebviewMessageHandler();
        };
    }, []);

    useEffect(() => {
        if (context.loggedIn !== null) return ;

        const autoLogin = localStorage.getItem("autoLogin") === "true";
        Api.refreshAuth({
            autoLogin: autoLogin,
            displayLang: common.getLang(),
        })
        .then((payload) => {
            return Api.getCurrentUserInfo({ optPermissions: false });
        })
        .then(async (payload) => {
            if (!payload.realname) {
                // console.log("has no realname of user : ", payload.name);
                return Api.logout().finally(() => {
                    localStorage.removeItem("autoLogin");
                    context.setLoggedOut();
                    navigate("/Login/SignIn");
                });
            }

            sendMessageToMobileAppWebview({type: "logged-in", login: true, autologin: autoLogin, _id: payload._id});

            const isReportSnapshot = await Api.fetchReportSnapshot({ registered_by: payload._id, as_boolean: true });
            if (isReportSnapshot) {
                localStorage.setItem(payload._id, isReportSnapshot);
            }
            else {
                localStorage.removeItem(payload._id);
            }

            sessionStorage.setItem("name", payload.name);
            sessionStorage.setItem("_id", payload._id);
            common.storePermissionsInfo(payload.optPermissions);

            context.setLoggedIn(true);
            context.setLoginProvider(null); // unknown
            console.log("login provider on sign-in : ", "unknown");
        })
        .catch((e) => {
            // Note. 브라우저 이동/갱신 시, 로그인 여부를 확인하게 되어,
            // 불필요하게 콘솔/서버에 로그를 쌓는 상황이 발생합니다.
            // 따라서, catch 상황이지만, 다음의 로그 출력 코드를 주석 처리합니다.
            // dgLogger.error(e)();
            setState((prev) => ({ ...prev, user: {} }));
            localStorage.removeItem("autoLogin");
            context.setLoggedOut();
        });
    }, [context, context.loggedIn, navigate]);

    useEffect(() => {
        if (!isMount.current) return;
        if (context.loggedIn === null) return ; // 로그 인(true), 아웃(false) 여부를 알지 못함.

        if (!context.loggedIn) {
            setState((prev) => ({...prev, user: {}, avatarUrl: null }));
            sessionStorage.removeItem('_id');
            sessionStorage.removeItem("name");
            common.storePermissionsInfo(null);
            return;
        }

        if (userInfo) {
            sessionStorage.setItem("name", userInfo.name);
            common.storePermissionsInfo(userInfo.optPermissions);
            setState((prev) => ({ ...prev, user: userInfo, avatarUrl: userInfo.profile_picture ? userInfo.profile_picture.url : null }));
        }
    }, [context.loggedIn, userInfo, isMount]);

    const popups = () => {
        switch (state.popup) {
            case POPUP.AskQuestion: 
                return <AskQuestion user={state.user} onCancel={() => setState({ ...state, popup: POPUP.None })} />;
            case POPUP.LoginRecommend: 
                return <LoginRecommendWindow onConfirm={() => { setState({ ...state, popup: POPUP.None }); navigate(`/Login/SignIn`); }} onCancel={() => setState({ ...state, popup: POPUP.None })} />;
            case POPUP.GroupParticipation: 
                return <GroupParticipationWindow onConfirm={() => { setState({ ...state, popup: POPUP.None }); navigate(`/Project`); }} onCancel={() => setState({ ...state, popup: POPUP.None })} />;
            case POPUP.CheckForTempReport: 
                return <CheckForTempReportWindow onCancel={() => setState({ ...state, popup: POPUP.None })} onClose={() => setState({ ...state, popup: POPUP.None })} />;
            case POPUP.GeneralError: {
                console.log("general warning on navigation");
                return (
                    <GeneralErrorWindow
                        message={state.popupTarget}
                        onClick={() => {
                            navigate("/");
                        }}
                    />
                );
            }
            case POPUP.None: break;
            default:
                console.log(`Can not handle ${state.popup} popup type`);
        }
    };

    const getAvatarCircle = (user) => {
        if (!user || !user.name) return <div />;
        if (state.avatarUrl) return <img src={state.avatarUrl} alt="Profile" className="avatar" />;
        return <span className="avatar avatar-70 bg-secondary text-white rounded-circle">{state.user.name.charAt(0).toUpperCase()}</span>;
    };

    return (
        <div id="navigator">
            {process.env.REACT_APP_GLOBAL_TOP_BANNER === "true" && (
                <Row className="gx-0 top-banner justify-content-center">
                    <Col className="col-auto">
                        {t("526")}&nbsp;<button onClick={() => setState({ ...state, popup: POPUP.AskQuestion })}>{t("7")}</button>
                    </Col>
                </Row>
            )}
            {popups()}
            {/* for desktop */}
            <TabletOrPC>
                <Container>
                    <Row className="gx-0">
                        <Col>
                            <Row className="gx-0 align-items-center navigation-bar">
                                <Col style={{ maxWidth: "33%" }}>
                                    <Link to="/" className="main-menu" style={{ overflowWrap: "break-word" }}>
                                        <span className="ocean c-fff font-size-46">OCEAN</span>
                                        <span className="cloud c-fff font-size-31">Cloud</span>
                                    </Link>
                                </Col>
                                <Col className="text-center" style={{ maxWidth: "11%" }}>
                                    <Link to="/Report" className="c-fff nanumsquare-800 font-size-22 report">
                                        {t("2")}
                                    </Link>
                                </Col>
                                <Col className="text-center" style={{ maxWidth: "11%" }}>
                                    <Link to="/Project" className="c-fff nanumsquare-800 font-size-22 project">
                                        {t("3")}
                                    </Link>
                                </Col>
                                <Col className="text-center" style={{ maxWidth: "11%" }}>
                                    <span
                                        className="c-fff nanumsquare-800 font-size-22"
                                        id="more"
                                        onClick={() => {
                                            const moreBox = document.getElementById("more-box");
                                            const moreText = document.getElementById("more-text");
                                            if (moreBox.style.display === "none" || moreBox.style.display === "") {
                                                moreBox.style.display = "block";
                                                moreText.style.display = "none";
                                                moreBox.focus();
                                            } else {
                                                moreBox.style.display = "none";
                                                moreText.style.display = "unset";
                                            }
                                        }}
                                    >
                                        <span id="more-text" className="c-fff" style={{ zIndex: "2", position: "relative" }}>
                                            {t("4")}
                                        </span>
                                        <span
                                            id="more-box"
                                            tabIndex={0}
                                            onBlur={(e) => {
                                                if (!e.currentTarget.contains(e.relatedTarget)) {
                                                    const moreBox = document.getElementById("more-box");
                                                    const moreText = document.getElementById("more-text");
                                                    moreBox.style.display = "none";
                                                    moreText.style.display = "unset";
                                                }
                                            }}
                                        >
                                            <span className="c-fff">{t("4")}</span>
                                            <div style={{ textAlign: "center" }}>
                                                <img
                                                    src={process.env.PUBLIC_URL + `nav_arrow@2x.png`}
                                                    srcSet={`${process.env.PUBLIC_URL}/nav_arrow@2x.png 2x`}
                                                    style={{ width: "13px", height: "6px" }}
                                                    alt=""
                                                />
                                            </div>
                                            <div id="more-box-menu">
                                                {/* 사이트 소개 */}
                                                <div className="pb-4" style={{lineHeight: 1}}>
                                                    <Link to="/Introduction" className="nanumsquare-400 font-size-16">
                                                        {t("5")}
                                                    </Link>
                                                </div>
                                                {/* 공지 사항 */}
                                                <div className="pb-4" style={{lineHeight: 1}}>
                                                    <Link to="/notice" className="nanumsquare-400 font-size-16 text-break">
                                                        {t("916")}
                                                    </Link>
                                                </div>
                                                {/* 사용 가이드 */}
                                                <div className="pb-4" style={{lineHeight: 1}}>
                                                    <Link to="/userguide" className="nanumsquare-400 font-size-16">
                                                        {t("620")}
                                                    </Link>
                                                </div>
                                                {/* 약관 및 정책 */}
                                                <div className="pb-4" style={{lineHeight: 1}}>
                                                    <Link to="/PrivacyPolicy" className="nanumsquare-400 font-size-16">
                                                        {t("6")}
                                                    </Link>
                                                </div>
                                                {/* <div><Link to="/Faq" className="nanumsquare-400 font-size-16">FAQ</Link></div> */}
                                                {/* 문의하기 */}
                                                <div className="pb-4" style={{lineHeight: 1}}>
                                                    <Link to="#" className="nanumsquare-400 font-size-16" onClick={() => setState({ ...state, popup: POPUP.AskQuestion })}>
                                                        {t("7")}
                                                    </Link>
                                                </div>
                                                {/* 바다기사단 커뮤니티 */}
                                                <div style={{lineHeight: 1}}>
                                                    <a href="https://oceanknights.net" target="_self"
                                                        className="nanumsquare-400 font-size-16"
                                                    >
                                                        {t("12")}
                                                    </a>
                                                </div>
                                            </div>
                                        </span>
                                    </span>
                                </Col>
                                {/* <Col className="col-auto" style={{width:"10%"}} /> */}
                                <Col className="text-end">
                                    <span className="area">
                                        <Row className="gx-0 align-items-center justify-content-between">
                                            <Col className="text-start create-report-wrap col-lg-12 col-xl-auto col-auto">
                                                <Link
                                                    onClick={(e) => {
                                                        if (!userInfo) {
                                                            e.preventDefault();
                                                            setState({ ...state, popup: POPUP.LoginRecommend });
                                                        } else if (userInfo.groups.length <= 1) {
                                                            e.preventDefault();
                                                            setState({ ...state, popup: POPUP.GroupParticipation });
                                                        } else if (localStorage.getItem(userInfo._id)) {
                                                            e.preventDefault();
                                                            setState({ ...state, popup: POPUP.CheckForTempReport });
                                                        }
                                                    }}
                                                    to="/CreateReport"
                                                    className="c-fff nanumsquare-800 font-size-18 create-report"
                                                >
                                                    {t("8")}
                                                </Link>
                                            </Col>
                                            <Col className="col-auto position-relative" style={{ padding: "12px 0", whiteSpace: "nowrap" }}>
                                                {context.loggedIn === false ? (
                                                    <Link to="/Login/SignIn" className="c-fff nanumsquare-700 font-size-14 login">
                                                        {t("9")}
                                                    </Link>
                                                ) : undefined}
                                                {context.loggedIn === true ? (
                                                    <Link to="/Mypage/MyInfo" className="c-fff nanumsquare-700 font-size-14 my-page">
                                                        <Trans i18nKey={"10"} components={{tag: <></>}} />
                                                    </Link>
                                                ) : undefined}
                                                {notifications && notifications.length > 0 && context.loggedIn ? (
                                                    <span
                                                        className="c-fff nanumsquare-800 font-size-11 position-absolute top-0 end-0 menu-badge rounded-pill bg-red"
                                                        style={{ transform: "translateY(-50%)" }}
                                                    >
                                                        {notifications.length > 9 ? "9+" : notifications.length}
                                                    </span>
                                                ) : undefined}
                                            </Col>
                                        </Row>
                                    </span>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </TabletOrPC>

            {/* for mobile */}
            <Mobile>
                <Container>
                    <Row id="mobile-side-nav" className="gx-0 d-sm-flex d-md-none mobile-side-nav flex-nowrap">
                        <Col className="col-auto bg-white">
                            <div className="pt-25px pr-25px pb-25px pl-25px overflow-auto" style={{ height: "100vh" }}>
                                {context.loggedIn || (
                                    <Row className="login-row gx-0 align-items-center pb-27px flex-nowrap">
                                        <Col className="col-auto">
                                            <button
                                                onClick={() => {
                                                    document.getElementById("mobile-side-nav").style.width = "0";
                                                    navigate("/Login/SignIn");
                                                }}
                                                className="c-fff login nanumsquareb-14-force lh-1"
                                            >
                                                {t("9")}
                                            </button>
                                        </Col>
                                        <Col className="col-auto w-5px"></Col>
                                        <Col className="col-auto text-start">
                                            <button
                                                onClick={() => {
                                                    document.getElementById("mobile-side-nav").style.width = "0";
                                                    navigate("/Login/SignUp");
                                                }}
                                                className="c-fff register nanumsquareb-14-force lh-1"
                                            >
                                                {t("292")}
                                            </button>
                                        </Col>
                                    </Row>
                                )}

                                {context.loggedIn && (
                                    <Row className="gx-0 align-items-center login-row pb-25px">
                                        <Col className="sidebar-user-item w-250px overflow-hidden">
                                            <Row className="gx-0 align-items-center pt-17px pr-30px pb-23px pl-30px flex-nowrap">
                                                <Col className="col-auto" style={{ width: "70px", height: "70px" }}>
                                                    {getAvatarCircle(state.user)}
                                                </Col>
                                                <Col className="pl-18px">
                                                    <div>
                                                        <div className="c-white notosanskr-18b text-truncate-3ln text-break">{state.user.name}</div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="gx-0 align-items-center pb-20px justify-content-center flex-nowrap">
                                                <Col className="col-auto">
                                                    <button
                                                        onClick={() => {
                                                            document.getElementById("mobile-side-nav").style.width = "0";
                                                            navigate("/Mypage/MyInfo");
                                                        }}
                                                        className="c-fff mypage notosanskr-13 lh-1 text-nowrap"
                                                    >
                                                        <Trans i18nKey={"10"} components={{tag: <></>}} />
                                                    </button>
                                                </Col>
                                                <Col className="col-auto w-5px"></Col>
                                                <Col className="col-auto text-start">
                                                    <button
                                                        onClick={() => {
                                                            document.getElementById("mobile-side-nav").style.width = "0";

                                                            if (isRunOnMobileAppWebview()) {
                                                                sendMessageToMobileAppWebview({
                                                                    type: "logout",
                                                                    social: (context.loginProvider && context.loginProvider != "email"),
                                                                    provider: context.loginProvider
                                                                });
                                                            }

                                                            Api.logout().finally(() => {
                                                                localStorage.removeItem("autoLogin");
                                                                context.setLoggedOut();
                                                                navigate("/");
                                                            });
                                                        }}
                                                        className="c-fff logout notosanskr-13 lh-1 text-nowrap"
                                                    >
                                                        {t("354")}
                                                    </button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                )}
                                <Row className="report-row gx-0 align-items-center maxh-55px h-55px text-nowrap">
                                    <Col>
                                        <button
                                            onClick={() => {
                                                document.getElementById("mobile-side-nav").style.width = "0";
                                                navigate("/Report");
                                            }}
                                            className="c-black notosanskr-16b"
                                        >
                                            {t("2")}
                                        </button>
                                    </Col>
                                </Row>
                                <Row className="project-row gx-0 align-items-center maxh-55px h-55px text-nowrap">
                                    <Col>
                                        <button
                                            onClick={() => {
                                                document.getElementById("mobile-side-nav").style.width = "0";
                                                navigate("/Project");
                                            }}
                                            className="c-black notosanskr-16b"
                                        >
                                            {t("3")}
                                        </button>
                                    </Col>
                                </Row>
                                <Row className="more-row pt-13px text-nowrap">
                                    <Col>
                                        {/* 사이트 소개 */}
                                        <div>
                                            <button
                                                onClick={() => {
                                                    document.getElementById("mobile-side-nav").style.width = "0";
                                                    navigate("/Introduction");
                                                }}
                                                className="c-black notosanskr-16b"
                                            >
                                                {t("5")}
                                            </button>
                                        </div>
                                        {/* 공지사항 */}
                                        <div>
                                            <button
                                                onClick={() => {
                                                    document.getElementById("mobile-side-nav").style.width = "0";
                                                    navigate("/notice");
                                                }}
                                                className="c-black notosanskr-16b text-break"
                                            >
                                                {t("916")}
                                            </button>
                                        </div>
                                        {/* 사용 가이드 */}
                                        <div>
                                            <button
                                                onClick={() => {
                                                    document.getElementById("mobile-side-nav").style.width = "0";
                                                    navigate("/userguide");
                                                }}
                                                className="c-black notosanskr-16b"
                                            >
                                                {t("620")}
                                            </button>
                                        </div>
                                        {/* 약관 및 정책 */}
                                        <div>
                                            <button
                                                onClick={() => {
                                                    document.getElementById("mobile-side-nav").style.width = "0";
                                                    navigate("/PrivacyPolicy");
                                                }}
                                                className="c-black notosanskr-16b"
                                            >
                                                {t("6")}
                                            </button>
                                        </div>
                                        {
                                            // <div><Link to="/Faq" className="nanumsquare-400 font-size-16">FAQ</Link></div>
                                        }
                                        {/* 문의하기 */}
                                        <div>
                                            <button
                                                onClick={() => {
                                                    document.getElementById("mobile-side-nav").style.width = "0";
                                                    setState({ ...state, popup: POPUP.AskQuestion });
                                                }}
                                                className="c-black notosanskr-16b"
                                            >
                                                {t("7")}
                                            </button>
                                        </div>
                                        {/* 바다기사단 커뮤니티 */}
                                        <div>
                                            <a className="text-decoration-none"
                                                href="https://oceanknights.net"
                                                target="_self"
                                            >
                                                <button
                                                    className="c-black notosanskr-16b"
                                                    onClick={(e) => e.stopPropagation()}
                                                >
                                                    {t("12")}
                                                </button>
                                            </a>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col className="mb-auto text-start pl-27px pt-27px">
                            <img
                                className="close-button"
                                src={process.env.PUBLIC_URL + `/popup-close.png`}
                                srcSet={`${process.env.PUBLIC_URL}/popup-close@2x.png 2x`}
                                width="26px"
                                onClick={() => {
                                    document.getElementById("mobile-side-nav").style.width = "0%";
                                }}
                                alt=""
                            />
                        </Col>
                    </Row>

                    <Row className="navigation-bar gx-0 d-sm-block d-md-none minh-65px">
                        <Col className="col-auto pt-5%">
                            <div className="">
                                <img
                                    className="drawer-menu"
                                    src={`${process.env.PUBLIC_URL}/hambuger_menu_icon.svg`}
                                    alt=""
                                    onClick={() => {
                                        document.getElementById("mobile-side-nav").style.width = "100%";
                                    }}
                                />
                            </div>
                        </Col>
                        <Col className="pt-5% pl-4% text-break">
                            <Link to="/" className="main-menu">
                                <span className="ocean c-fff">OCEAN</span>
                                <span className="cloud c-fff">Cloud</span>
                            </Link>
                        </Col>

                        <Col className="col-auto create-report-wrap text-start minw-140px pl-4% d-flex align-items-center">
                            <Link
                                onClick={(e) => {
                                    if (!userInfo) {
                                        e.preventDefault();
                                        setState({ ...state, popup: POPUP.LoginRecommend });
                                    } else if (userInfo.groups.length <= 1) {
                                        e.preventDefault();
                                        setState({ ...state, popup: POPUP.GroupParticipation });
                                    }
                                }}
                                to="/CreateReport"
                                className="c-fff notosanskr-14:sm create-report"
                            >
                                <div className="text-break">
                                    {t("8").split("\n").map((v, i) => <div key={i}>{v}</div>)}
                                </div>
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </Mobile>
        </div>
    );
}
