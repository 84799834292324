import React, { useState, useEffect, useRef, useContext, useCallback } from "react";
import Api from "../../Api";
import { utility } from "@ocean-knight/shared";
import { AppContext } from "../../AppContext";
import { POPUP } from "../../common/defines";
import GeneralErrorWindow from "../common/GeneralErrorWindow";
import { useLocation, useNavigate } from "react-router-dom";
import common from "../../common/common";
import Modal from "../common/Modal";
import Pagination from '../common/Pagination';
import SiteMemberInfoWindow from "./ManageMember/SiteMemberInfoWindow";
import OrganizationInfoWindow from './../common/OrganizationInfoWindow';
import { useIsMount, useNotification } from "../../common/customHook";
import { mutate } from "swr";
import CustomCheckbox from './../common/CustomCheckbox';
import { Tabs, Tab, Row, Col } from "react-bootstrap";
import "./ApproveOrganization.css";
import dgLogger from "../../common/dgLogger";
import { useTranslation } from "react-i18next";
import QueryString from 'qs';
import { APPROVE_ORGANIZATION_TAB_ID } from "@ocean-knight/shared/src/utility";

const ID = {
    rejectReason: "organization-reject-reason",
    removeReason: "organization-remove-reason",

    checkAll: "organization-approval-check-all",
    buttonApprove: "organization-button-approve",
    buttonReject: "organization-button-reject",
};

export default function ApproveOrganization() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const queryData = QueryString.parse(location.search, { ignoreQueryPrefix: true });
    const [state, setState] = useState({
        activeTab: [APPROVE_ORGANIZATION_TAB_ID.reviews, APPROVE_ORGANIZATION_TAB_ID.histories].includes(queryData.activeTab) ? queryData.activeTab : APPROVE_ORGANIZATION_TAB_ID.reviews, // 현재 tab
        popup: POPUP.None,
        popupTarget: null,
        groups: [], // group information
        reviews: [],
        histories: [],
        buttonDisabled: true,
    });
    const isMount = useIsMount();
    const context = useContext(AppContext);
    const { notifications } = useNotification(context.loggedIn);
    const reviewsPaginationOption = useRef({ currentPage: 1, itemsCountPerPage: 10, pageRangeDisplayed: 10, totalItemsCount: 0 });
    const historiesPaginationOption = useRef({ currentPage: 1, itemsCountPerPage: 10, pageRangeDisplayed: 10, totalItemsCount: 0 });

    const updateCheckboxesStatus = useCallback((reviews) => {
        let checked = 0;
        reviews.forEach((review) => {
            if (document.getElementById(review._id)?.checked) checked++;
        });

        document.getElementById(ID.checkAll).checked = checked > 0 && checked === reviews.length;
        document.getElementById(ID.checkAll).disabled = !(reviews?.length);
        setState((prev) => ({ ...prev, buttonDisabled: checked === 0 }));
    }, []);

    useEffect(() => {
        const hasPermission = common.hasGroupManagerPermission();
        if (!hasPermission) {
            if (!isMount.current) { return; }
            setState((prev) => ({
                ...prev,
                popup: POPUP.GeneralError,
                popupTarget: t("539"),
            }));
            return;
        }

        const getContents = async () => {
            if (!isMount.current) { return; }
            try {
                const groups = await Api.getAllGroupList();
                const reviewsPayload = await Api.getOrganizationHistoryAll({
                    states: [utility.STATE.REVIEWING],
                    currentPage: reviewsPaginationOption.current.currentPage,
                    itemsCountPerPage: reviewsPaginationOption.current.itemsCountPerPage,
                    sort: { requested_date: -1 },
                });
                reviewsPaginationOption.current.totalItemsCount = reviewsPayload.totalItemsCount;

                const resultsPayload = await Api.getOrganizationHistoryAll({
                    states: [utility.STATE.ACQUIRED, utility.STATE.REJECTED],
                    currentPage: historiesPaginationOption.current.currentPage,
                    itemsCountPerPage: historiesPaginationOption.current.itemsCountPerPage,
                    sort: { state_confirmed_date: -1 },
                });
                historiesPaginationOption.current.totalItemsCount = resultsPayload.totalItemsCount;

                updateCheckboxesStatus(reviewsPayload.histories);
                setState((prev) => ({ ...prev, groups: groups, reviews: reviewsPayload.histories, histories: resultsPayload.histories }));
            } catch (e) {
                dgLogger.error(e)();
                setState((prev) => ({ ...prev, popup: POPUP.GeneralError, popupTarget: e.toString() }));
            }
        };
        getContents();
    }, [updateCheckboxesStatus, t, isMount]);

    const updateOrganizationState = async (requestIDs, state_, reason) => {
        await Api.updateOrganizationHistory({
            ids: requestIDs,
            state: state_,
            state_reason: reason,
            lang: common.getLang()
        });

        let page = reviewsPaginationOption.current.currentPage;
        if (page > 1 && state.reviews.length === 1) page = page - 1;
        reviewsPaginationOption.current.currentPage = page;

        const payload = await Api.getOrganizationHistoryAll({
            states: [utility.STATE.REVIEWING],
            currentPage: reviewsPaginationOption.current.currentPage,
            itemsCountPerPage: reviewsPaginationOption.current.itemsCountPerPage,
            sort: { requested_date: -1 },
        });

        reviewsPaginationOption.current.totalItemsCount = payload.totalItemsCount;

        let mutated = false;
        for (const _id of requestIDs) {
            const notification = notifications?.find((notification) => notification.item === _id);
            if (notification) {
                await Api.removeMatchedNotification({ from: notification.from, item: notification.item });
                mutated = true;
            }
        }
        mutated && mutate("useNotification");

        return payload.histories;
    };

    const getUserInfo = async (_id) => {
        try {
            const payload = await Api.getUserInfo({ _id: _id });
            return payload;
        } catch (e) {
            dgLogger.error(e)();
            return null;
        }
    };

    const popups = () => {
        if (state.popup === POPUP.Approve) return popupOrganizationApprove();
        if (state.popup === POPUP.Reject) return popupOrganizationReject();
        if (state.popup === POPUP.RejectReason) return popupOrganizationRejectReason();
        if (state.popup === POPUP.UserInfo) return popupUserInfo();
        if (state.popup === POPUP.RequestContent) return popupOrganizationRequestContent();
        if (state.popup === POPUP.Remove) return popupOrganizationRemove();
        if (state.popup === POPUP.GeneralError)
            return (
                <GeneralErrorWindow
                    message={state.popupTarget}
                    onClick={() => {
                        navigate("/");
                    }}
                />
            );
    };

    const popupUserInfo = () => {
        const groupIds = state.popupTarget.groups.reduce((ids, cur) => {
            if (cur.group) ids.push(cur.group);
            return ids;
        }, []);

        return (
            <SiteMemberInfoWindow
                user={state.popupTarget}
                groups={state.groups.filter((group) => groupIds.includes(group._id))} // filter option 에 그룹이 빈값(전체) 라면 모든 그룹을
                onCancel={() => {
                    setState({ ...state, popup: POPUP.None, popupTarget: null });
                }}
            />
        );
    };

    const popupOrganizationRequestContent = () => {
        const organization = state.popupTarget;

        return (
            <OrganizationInfoWindow
                organization={organization}
                onClick={() => {
                    setState({ ...state, popup: POPUP.None, popupTarget: null });
                }}
            />
        );
    };

    const popupOrganizationApprove = () => {
        dgLogger.assert(state.popupTarget != null)();

        const icon = <img src={process.env.PUBLIC_URL + `/pop_public_complete.png`} srcSet={`${process.env.PUBLIC_URL}/pop_public_complete@2x.png 2x, ${process.env.PUBLIC_URL}/pop_public_complete@3x.png 3x`} alt=""/>;
        const header = <div>{t("666")}</div>;
        const body = <div>{t("667")}</div>;

        return (
            <Modal
                onRequestClose={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
                onConfirm={() => {
                    updateOrganizationState(state.popupTarget, utility.STATE.ACQUIRED, null)
                        .then((reviews) => {
                            updateCheckboxesStatus(reviews);
                            setState({ ...state, reviews: reviews, popup: POPUP.None, popupTarget: null });
                        })
                        .catch((e) => setState({ ...state, popup: POPUP.GeneralError, popupTarget: e.toString() }));
                }}
                onCancel={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
                icon={icon}
                header={header}
                body={body}
            />
        );
    };

    const popupOrganizationReject = () => {
        const header = <div>{t("668")}</div>;
        const body = <div>아래와 같은 사유로 신청을 반려합니다.</div>;

        return (
            <Modal
                onRequestClose={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
                onConfirm={() => {
                    const reason = document.getElementById(ID.rejectReason).value;
                    updateOrganizationState(state.popupTarget, utility.STATE.REJECTED, reason)
                        .then((reviews) => {
                            updateCheckboxesStatus(reviews);
                            setState({ ...state, reviews: reviews, popup: POPUP.None, popupTarget: null });
                        })
                        .catch((e) => {
                            dgLogger.error(e)();
                            setState({ ...state, popup: POPUP.GeneralError, popupTarget: e.toString() });
                        });
                }}
                onCancel={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
                header={header}
                body={body}
                textareaId={ID.rejectReason}
            />
        );
    };

    const popupOrganizationRejectReason = () => {
        const header = <div>{t("668")}</div>;
        const body = <div>{t("490")}</div>;

        return (
            <Modal
                onRequestClose={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
                onConfirm={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
                header={header}
                body={body}
                reason={state.popupTarget.state_reason}
            />
        );
    };

    const popupOrganizationRemove = () => {
        const header = <div>{t("669")}</div>;
        const body = <div>{t("670")}</div>;

        return (
            <Modal
                onRequestClose={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
                onConfirm={async () => {
                    const reason = document.getElementById(ID.removeReason).value;
                    await Api.updateOrganizationHistory({
                        ids: state.popupTarget,
                        state: utility.STATE.REMOVE,
                        state_reason: reason,
                        lang: common.getLang()
                    });
                    Api.getOrganizationHistoryAll({
                        states: [utility.STATE.ACQUIRED, utility.STATE.REJECTED],
                        currentPage: historiesPaginationOption.current.currentPage,
                        itemsCountPerPage: historiesPaginationOption.current.itemsCountPerPage,
                        sort: { state_confirmed_date: -1 },
                    })
                        .then((payload) => {
                            historiesPaginationOption.current.totalItemsCount = payload.totalItemsCount;
                            setState({ ...state, activeTab: APPROVE_ORGANIZATION_TAB_ID.histories, histories: payload.histories, popup: POPUP.None, popupTarget: null });
                        })
                        .catch((e) => {
                            dgLogger.error(e)();
                            setState({ ...state, popup: POPUP.GeneralError, popupTarget: e.toString() });
                        });
                }}
                onCancel={() => setState({ ...state, popup: POPUP.None, popupTarget: null })}
                header={header}
                body={body}
                textareaId={ID.removeReason}
                footer={t("465")}
            />
        );
    };

    const processedState = (item) => {
        if (item.state === utility.STATE.ACQUIRED)
            return (
                <>
                    <button className="nanumsquare-15b lh-1.2 ls--0.75px c-white" style={{ backgroundColor: "#4270e0", cursor: "default" }}>
                        {t("377")}
                    </button>
                    <button className="remove-organization"
                        onClick={() => setState({ ...state, popup: POPUP.Remove, popupTarget: [item._id] })}
                    />
                </>
            );
        else
            return (
                <button
                    className="nanumsquare-15b lh-1.2 ls--0.75px c-white"
                    style={{ backgroundColor: item.state === utility.STATE.REVIEWING ? "#87ac2f" : "#636363" }}
                    onClick={() => setState({ ...state, popup: POPUP.RejectReason, popupTarget: item })}
                >
                    {t("421")}
                </button>
            );
    };

    return (
        <div id="approve-organization">
            {popups()}

            <Row className="gx-0 page-title">
                <Col className="col-lg-auto notosanskr-40b first">{t("352")}</Col>
                <Col className="notosanskr-26 c-666 second d-table">
                    <div className="d-table-cell align-bottom">{t("671")}</div>
                </Col>
            </Row>

            <Tabs
                activeKey={state.activeTab}
                id="uncontrolled-tab"
                className="uncontrolled-tab"
                onSelect={(key) => {
                    if (state.activeTab === key) return;

                    switch (key) {
                        case APPROVE_ORGANIZATION_TAB_ID.reviews:
                            return Api.getOrganizationHistoryAll({
                                states: [utility.STATE.REVIEWING],
                                currentPage: reviewsPaginationOption.current.currentPage,
                                itemsCountPerPage: reviewsPaginationOption.current.itemsCountPerPage,
                                sort: { requested_date: -1 },
                            })
                                .then((payload) => {
                                    reviewsPaginationOption.current.totalItemsCount = payload.totalItemsCount;
                                    setState({ ...state, activeTab: APPROVE_ORGANIZATION_TAB_ID.reviews, reviews: payload.histories });
                                })
                                .catch((e) => {
                                    dgLogger.error(e)();
                                    setState({ ...state, popup: POPUP.GeneralError, popupTarget: e.toString() });
                                });

                        case APPROVE_ORGANIZATION_TAB_ID.histories:
                            return Api.getOrganizationHistoryAll({
                                states: [utility.STATE.ACQUIRED, utility.STATE.REJECTED],
                                currentPage: historiesPaginationOption.current.currentPage,
                                itemsCountPerPage: historiesPaginationOption.current.itemsCountPerPage,
                                sort: { state_confirmed_date: -1 },
                            })
                                .then((payload) => {
                                    historiesPaginationOption.current.totalItemsCount = payload.totalItemsCount;
                                    setState({ ...state, activeTab: APPROVE_ORGANIZATION_TAB_ID.histories, histories: payload.histories });
                                })
                                .catch((e) => {
                                    dgLogger.error(e)();
                                    setState({ ...state, popup: POPUP.GeneralError, popupTarget: e.toString() });
                                });
                        default:
                            return;
                    }
                }}
            >
                <Tab className={"reviews"} eventKey={APPROVE_ORGANIZATION_TAB_ID.reviews} title={t("413")}>
                    <Row className="item-row gx-0 align-items-center notosanskr-18-500">
                        <Col className="item col-lg-auto text-center w-80px">
                            <CustomCheckbox
                                id={ID.checkAll}
                                onChange={(e) => {
                                    state.reviews.forEach((review) => {
                                        document.getElementById(review._id).checked = e.target.checked;
                                    });

                                    setState({ ...state, buttonDisabled: !e.target.checked });
                                }}
                                disabled={!state.reviews.length}
                            />
                        </Col>
                        <Col className="item text-center">{t("374")}</Col>
                        <Col className="item text-center">{t("491")}</Col>
                        <Col className="item text-center">{t("400")}</Col>
                    </Row>
                    {state.reviews.map((item, index) => (
                        <Row key={item._id} className="item-row gx-0 align-items-center nanumsquareb-16">
                            <Col className="item col-lg-auto text-center w-80px">
                                <CustomCheckbox
                                    id={item._id}
                                    onChange={(_) => {
                                        updateCheckboxesStatus(state.reviews);
                                    }}
                                />
                            </Col>
                            <Col className="item text-center">{new Date(item.requested_date).toLocaleDateString()}</Col>
                            <Col className="item text-center as-link">
                                <span
                                    className="nanumsquareb-15"
                                    role="link"
                                    onClick={async () => {
                                        const info = await getUserInfo(item.user);
                                        if (info) {
                                            setState({ ...state, popup: POPUP.UserInfo, popupTarget: info });
                                        }
                                    }}
                                >
                                    {item.userName}
                                </span>
                            </Col>
                            <Col className="item text-center button">
                                <button
                                    className="c-white nanumsquareb-15"
                                    onClick={() => {
                                        Api.getOrganizationHistoryItem(item._id)
                                            .then((payload) => {
                                                setState({ ...state, popup: POPUP.RequestContent, popupTarget: payload });
                                            })
                                            .catch((e) => {
                                                dgLogger.error(e)();
                                            });
                                    }}
                                >
                                    {t("400")}
                                    <img src={`${process.env.PUBLIC_URL}/arrow.png`} 
                                    srcSet={`${process.env.PUBLIC_URL}/arrow@2x.png 2x, ${process.env.PUBLIC_URL}/arrow@3x.png 3x`} 
                                    alt="" />
                                </button>
                            </Col>
                        </Row>
                    ))}

                    <Row className="gx-0 button-group mt-30px">
                        <Col className="text-end">
                            <button
                                id={ID.buttonApprove}
                                className="approve notosanskr-18 lh-1.06 ls--0.45px c-white"
                                onClick={() => {
                                    const approveIDs = [];
                                    state.reviews.forEach((review) => {
                                        if (document.getElementById(review._id).checked) approveIDs.push(review._id);
                                    });
                                    setState({ ...state, popup: POPUP.Approve, popupTarget: approveIDs });
                                }}
                                disabled={state.buttonDisabled}
                            >
                                {t("377")}
                            </button>

                            <button
                                id={ID.buttonReject}
                                className="reject notosanskr-18 lh-1.06 ls--0.45px c-white"
                                onClick={() => {
                                    const rejectIDs = [];
                                    state.reviews.forEach((review) => {
                                        if (document.getElementById(review._id).checked) rejectIDs.push(review._id);
                                    });
                                    setState({ ...state, popup: POPUP.Reject, popupTarget: rejectIDs });
                                }}
                                disabled={state.buttonDisabled}
                            >
                                {t("421")}
                            </button>
                        </Col>
                    </Row>

                    <Pagination
                        ref={(newPage) => {
                            Api.getOrganizationHistoryAll({
                                states: [utility.STATE.REVIEWING],
                                currentPage: newPage,
                                itemsCountPerPage: reviewsPaginationOption.current.itemsCountPerPage,
                                sort: { requested_date: -1 },
                            })
                                .then((payload) => {
                                    reviewsPaginationOption.current.totalItemsCount = payload.totalItemsCount;
                                    reviewsPaginationOption.current.currentPage = newPage;
                                    setState({ ...state, reviews: payload.histories });
                                })
                                .catch((e) => {
                                    dgLogger.error(e)();
                                    setState({ ...state, popup: POPUP.GeneralError, popupTarget: e.toString() });
                                });
                        }}
                        page={reviewsPaginationOption.current.currentPage}
                        itemsCountPerPage={reviewsPaginationOption.current.itemsCountPerPage} // 페이지 당 아이템 개수
                        totalItemsCount={reviewsPaginationOption.current.totalItemsCount} // 총 아이템 개수
                        pageRangeDisplayed={reviewsPaginationOption.current.pageRangeDisplayed} // 페이지 범위
                    />
                </Tab>

                <Tab className={"histories"} eventKey={APPROVE_ORGANIZATION_TAB_ID.histories} title={t("414")}>
                    <Row className="item-row gx-0 align-items-center notosanskr-18-500">
                        <Col className="item text-center">{t("374")}</Col>
                        <Col className="item text-center">{t("420")}</Col>
                        <Col className="item text-center">{t("491")}</Col>
                        <Col className="item text-center">{t("400")}</Col>
                        <Col className="item text-center">{t("376")}</Col>
                    </Row>

                    {state.histories.map((item, index) => (
                        <Row key={item._id} className="item-row gx-0 align-items-center nanumsquareb-16">
                            <Col className="item text-center">{new Date(item.requested_date).toLocaleDateString()}</Col>
                            <Col className="item text-center">{new Date(item.state_confirmed_date).toLocaleDateString()}</Col>
                            <Col className="item text-center as-link">
                                <span
                                    className="nanumsquareb-15"
                                    role="link"
                                    onClick={async () => {
                                        const info = await getUserInfo(item.user);
                                        if (info) {
                                            setState({ ...state, popup: POPUP.UserInfo, popupTarget: info });
                                        }
                                    }}
                                >
                                    {item.userName}
                                </span>
                            </Col>
                            <Col className="item text-center button">
                                <button
                                    className="c-white nanumsquareb-15"
                                    onClick={() => {
                                        Api.getOrganizationHistoryItem(item._id)
                                            .then((payload) => {
                                                setState({ ...state, popup: POPUP.RequestContent, popupTarget: payload });
                                            })
                                            .catch((e) => {
                                                dgLogger.error(e)();
                                            });
                                    }}
                                >
                                    {t("400")}
                                    <img src={`${process.env.PUBLIC_URL}/arrow.png`}
                                    srcSet={`${process.env.PUBLIC_URL}/arrow@2x.png 2x, ${process.env.PUBLIC_URL}/arrow@3x.png 3x`}
                                    alt="" />
                                </button>
                            </Col>
                            <Col className="item text-center process-state">{processedState(item)}</Col>
                        </Row>
                    ))}
                    <Pagination
                        ref={(newPage) => {
                            Api.getOrganizationHistoryAll({
                                states: [utility.STATE.ACQUIRED, utility.STATE.REJECTED],
                                currentPage: newPage,
                                itemsCountPerPage: historiesPaginationOption.current.itemsCountPerPage,
                                sort: { state_confirmed_date: -1 },
                            })
                                .then((payload) => {
                                    historiesPaginationOption.current.totalItemsCount = payload.totalItemsCount;
                                    historiesPaginationOption.current.currentPage = newPage;
                                    setState({ ...state, histories: payload.histories });
                                })
                                .catch((e) => {
                                    dgLogger.error(e)();
                                    setState({ ...state, popup: POPUP.GeneralError, popupTarget: e.toString() });
                                });
                        }}
                        page={historiesPaginationOption.current.currentPage}
                        itemsCountPerPage={historiesPaginationOption.current.itemsCountPerPage} // 페이지 당 아이템 개수
                        totalItemsCount={historiesPaginationOption.current.totalItemsCount} // 총 아이템 개수
                        pageRangeDisplayed={historiesPaginationOption.current.pageRangeDisplayed} // 페이지 범위
                    />
                </Tab>
            </Tabs>
        </div>
    );
}
