import React, { useState, forwardRef, useEffect, useCallback, useMemo, useImperativeHandle, useRef, useContext } from 'react';
import WithImageHandle from "../hoc/WithImageHandle";
import useFileHook from './FileHook';
import FileView from "./FileView";
import useGPSContext from '../Context/GPSContext';
import useDatetimeContext from '../Context/DatetimeContext';
import useSizeContext from "../Context/SizeContext";
import dgLogger from '../../../common/dgLogger';
import common from '../../../common/common';
import { isRunOnIosAppWebview, sendMessageToMobileAppWebview, setHandleMobileAppWebviewMessages } from '../../common/RNBridge';

const MAX_FILES = 20;

const File = forwardRef(({ defaultValue, type, fields, essential, defaultRepresentativePicture, i18n }, ref) => {
    const { title, description, required, max_item_count, min_item_count } = useMemo(() => {
        const lang = common.getLang();
            if (i18n?.[lang]) {
                return ({...fields, ...i18n[lang].fields});
            } else return fields;
    }, [fields, i18n]);
    const [containFiles, setContainFiles] = useState([]);
    const [representative, setRepresentative] = useState(null);
    const [pendingUploadFiles, setPendingUploadFiles] = useState([]);
    const [errors, setErrors] = useState({ [type]: { state: true, message: '' } });
    const fileListLength = useRef(0);
    const {
        imageValidation,
        pushDeletedFilesKey,
        pushAddedFilesKey,
        setFileValue,
        getFileValue,
        setRepresentativePicture,
        processUploadedFiles,
        compareFile,
        uploadImage
    } = useFileHook(ref);

    const { GPSContext } = useGPSContext();
    const gpsContext = useContext(GPSContext);

    const { DatetimeContext } = useDatetimeContext();
    const datetimeContext = useContext(DatetimeContext);

    const { SizeContext } = useSizeContext();
    const sizeContext = useContext(SizeContext);

    const pendingUploadFilesForIOS = useRef([]);

    useEffect(() => {
        setHandleMobileAppWebviewMessages("current-location", ({payload, error}) => {
            if (error) {
                return window.alert(`has error on file upload: ${JSON.stringify(error)}`);
            }

            setContainFiles(payload);
            setPendingUploadFiles(pendingUploadFilesForIOS.current);
            pendingUploadFilesForIOS.current = [];
        });
        return () => {
            setHandleMobileAppWebviewMessages("current-location", undefined);
        };
    }, [containFiles]);

    // 기본 값 (수정하기로 넘어온 값) 이 있다면 값을 저장
    useEffect(() => {
        if (defaultValue && !getFileValue()) {
            setFileValue(defaultValue || []);
            setRepresentative(defaultRepresentativePicture || null);
            setRepresentativePicture(defaultRepresentativePicture || null);
            setContainFiles(defaultValue || []);
            fileListLength.current = defaultValue?.length || 0;
            const originalSize = defaultValue.reduce((acc,value) => acc += value.size, 0);
            sizeContext.addOriginalUploadedSize(originalSize);
        }
    }, [defaultValue, defaultRepresentativePicture, sizeContext, setFileValue, getFileValue, setRepresentativePicture]);

    // 대표 사진인 지 판별
    const isRepresentative = useCallback((file) => {
        if (essential && compareFile(representative, file))
            return true;
        return false;
    }, [essential, representative, compareFile]);

    // cloud에 업로드 되어야 할 파일이 생겼을 때 실행
    useEffect(() => {
        if (pendingUploadFiles.length) {
            Promise.all(pendingUploadFiles.map(async (file, index) => (
                uploadImage(file).then(fileInfo => {
                    if (fileInfo.key) pushAddedFilesKey(fileInfo.key); // file key가 있는 경우 추가 된 key 목록에 추가
                    setContainFiles(prev => (
                        prev.map(cur => {
                            const flag = compareFile(fileInfo, cur);
                            if (isRepresentative(fileInfo)) {
                                // resize_url, resizeImageSize을 제외한 정보를 대표 사진에 저장
                                const { resize_url, resizeImageSize, ...info } = {...cur, ...fileInfo};
                                setRepresentativePicture(info);
                            }
                            return flag ? {...cur, ...fileInfo} : cur;
                        })
                    ));
                })
            ))).then(() => {
                setPendingUploadFiles([]);
            });
        }
    }, [pendingUploadFiles, uploadImage, compareFile, isRepresentative, pushAddedFilesKey, setRepresentativePicture]);

    // 현재 저장 된 값을 이용하여 validation 진행 후 error 갱신
    const validation = useCallback(() => {
        const { valid, errorCollection } = imageValidation({ required, errorKey: type, min_item_count, fileListLength: fileListLength.current });
        setErrors(errorCollection);

        return valid;
    }, [type, required, min_item_count, imageValidation]);

    // 대표 사진을 바꾸어주는 함수
    // 대표 사진이 바뀔 때 context에 정보 갱신
    const changeRepresentative = useCallback((file) => {
        if (essential) {
            if (!file) {
                gpsContext.setGPS({ latitude: null, longitude: null });
                datetimeContext.setDatetime(null);
                setRepresentativePicture(null);
                setRepresentative(null);
            }
            else if (!compareFile(representative, file)) {
                gpsContext.setGPS({ latitude: file?.latitude, longitude: file?.longitude });
                datetimeContext.setDatetime(file?.dateTime || null);
                setRepresentativePicture(file);
                setRepresentative(file);
            }
        }
    }, [essential, representative, gpsContext, datetimeContext, compareFile, setRepresentativePicture]);

    // 모든 파일이 cloud에 업로드 되었을 때 실행
    useEffect(() => {
        // if (!pendingUploadFiles.length && containFiles.length) {
        if (!pendingUploadFiles.length) {
            setFileValue(containFiles);
            if (!representative && containFiles[0]) {
                changeRepresentative(containFiles[0]);
            }
        }
    }, [pendingUploadFiles, containFiles, representative, setFileValue, changeRepresentative]);

    const shouldHandleOnOutOfiOSAppWebview = (fileList) => {
        // 단일 파일이 아니라면, ios 의 webview 상에서 직접 촬영된 사진이 아닐것으로 의심됨.
        const isSingleFile = fileList?.length == 1;
        if (!isSingleFile) return false;

        const file = fileList[0];

        // 특정 파일명이 아니면, ios 의 webview 상에서 직접 촬영된 사진이 아닐것으로 의심됨.
        const possibleCameraTakenFile = ["image.jpg", "image.jpeg"].includes(file.name);
        if (!possibleCameraTakenFile) return false;

        // exif 정보가 있다면, ios 의 webview 상에서 직접 촬영된 사진이 아닐것으로 의심됨.
        if (file.latitude || file.longitude || file.dateTime) return false;

        return isRunOnIosAppWebview();
    };

    // 사진이 업로드 됐을 때의 handler
    const onDrop = useCallback(async (acceptedFiles, fileRejections) => {
        try {
            const { newFileList, fileList } = await processUploadedFiles({
                acceptedFiles,
                fileRejections,
                containFiles,
                max_item_count: max_item_count || MAX_FILES,
            });
            if (!newFileList.length) return validation();

            fileListLength.current = fileList.length;
            validation();

            const updatedFiles = newFileList.map(file => ({
                latitude: file.latitude,
                longitude: file.longitude,
                dateTime: file.dateTime,
                name: file.name,
                size: file.size,
                url: `${process.env.PUBLIC_URL}/Rolling-1.4s-200px.gif`
            }));

            if (shouldHandleOnOutOfiOSAppWebview(newFileList)) {
                sendMessageToMobileAppWebview({
                    type: "get-current-location",
                    payload: {
                        containFiles: containFiles,
                        updatedFiles: updatedFiles
                    }
                });
                pendingUploadFilesForIOS.current = newFileList;
            } else {
                setContainFiles((prev => [...prev, ...updatedFiles]));
                setPendingUploadFiles(newFileList);
            }
        }
        catch (e) {
            dgLogger.info(e)();
        }
    }, [containFiles, max_item_count, processUploadedFiles, validation]);

    // remove button을 눌렀을 때의 handler
    const removeFile = useCallback((file) => {
        sizeContext.subtractOriginalUploadedSize(file.size);
        if (file.key) pushDeletedFilesKey(file.key); // file key가 있는 경우 삭제 할 key 목록에 추가

        const newContainFiles = containFiles.filter(containFile => !compareFile(containFile, file));

        fileListLength.current = newContainFiles.length;
        validation();

        if (isRepresentative(file)) {
            const index = containFiles.findIndex(containFile => compareFile(containFile,file));
            changeRepresentative(index !== 0 ? containFiles[0] : containFiles[1] || null);
        }

        setContainFiles(newContainFiles);
    }, [sizeContext, containFiles, pushDeletedFilesKey, compareFile, validation, isRepresentative, changeRepresentative]);

    // validation에 필요한 정보 노출
    useImperativeHandle(ref, () => (
        { ...ref.current, validation }
    ), [ref, validation]);

    const viewProps = {
        title,
        description,
        type,
        invalid: errors[type]?.state ? "" : "invalid",
        errorMessage: errors[type]?.message,
        maxFiles: max_item_count || MAX_FILES,
        containFiles,
        representative,
        onDrop,
        removeFile,
        changeRepresentative,
        isRepresentative,
    };

    return (
        <FileView {...viewProps} />
    );
});

export default WithImageHandle(File);
